import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { useEffect } from "react";
import { post } from "../../shared/http/httpService";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { validatePassword } from "../../shared/helper/validations";

const PasswordModal = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [passwords, setPasswords] = React.useState({});
  const [valid, setValid] = React.useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [errors, setErrors] = React.useState({
    current: { message: undefined, error: false },
    new: { message: undefined, error: false },
    repeat: { message: undefined, error: false },
  });

  useEffect(() => {
    setPasswords({ current: undefined, new: undefined, repeat: undefined });
  }, []);

  useEffect(() => {
    if (
      passwords.current === undefined ||
      errors.current.error ||
      passwords.new === undefined ||
      errors.new.error ||
      errors.new.message !== "" ||
      passwords.repeat === undefined ||
      errors.repeat.error
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [passwords, errors]);

  const handleClose = () => {
    setPasswords({});
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowRepeatPassword(false);
    setErrors({
      current: { message: undefined, error: false },
      new: { message: undefined, error: false },
      repeat: { message: undefined, error: false },
    });
    props.onClose();
  };

  const handleSave = () => {
    const body = {
      username: props.auth.name,
      currentPassword: passwords.current,
      newPassword: passwords.new,
      repeatPassword: passwords.repeat,
    };
    post("/users/changePassword", body)
      .then(() => {
        props.onClose();
        enqueueSnackbar(t("preferences:passwordUpdated"), {
          variant: "success",
        });
      })
      .catch((error) => {
        if (error.error === "Invalid password") {
          handleInvalidPassword();
        } else {
          props.onClose();
          enqueueSnackbar(t("preferences:errorPasswordUpdate"), {
            variant: "error",
          });
        }
      });
  };

  const updateCurrentPassword = (e) => {
    validateCurrentPassword(e.target.value);
    setPasswords({
      current: e.target.value,
      new: passwords.new,
      repeat: passwords.repeat,
    });
  };

  const updateNewPassword = (e) => {
    validateNewPassword(e.target.value);
    setPasswords({
      current: passwords.current,
      new: e.target.value,
      repeat: passwords.repeat,
    });
  };

  const updateRepeatPassword = (e) => {
    validateRepeatPassword(e.target.value);
    setPasswords({
      current: passwords.current,
      new: passwords.new,
      repeat: e.target.value,
    });
  };

  const checkCurrentPassword = async (currentPassword) => {
    const body = {
      username: props.auth.name,
      currentPassword: currentPassword,
    };
    const correctPassword = await post("/users/checkPassword", body);

    return correctPassword;
  };

  const validateCurrentPassword = async (currentPassword) => {
    let newError = {
      current: { message: errors.current.message, error: errors.current.error },
      new: { message: errors.new.message, error: errors.new.error },
      repeat: {
        message: errors.repeat.message,
        error: errors.repeat.error,
      },
    };

    let validPassword = await checkCurrentPassword(currentPassword);

    newError.current.message = "";
    newError.current.error = false;

    if (currentPassword !== "" && !validPassword) {
      newError.current.message = t("preferences:invalidCurrentPassword");
      newError.current.error = true;
    }
    if (currentPassword === "") {
      newError.current.message = t("preferences:errorCurrentPasswordRequired");
      newError.current.error = true;
    }

    setErrors(newError);
  };

  const validateNewPassword = async (newPassword) => {
    let newError = {
      current: { message: errors.current.message, error: errors.current.error },
      new: { message: "", error: false },
      repeat: { message: "", error: false },
    };

    if (newPassword === "") {
      newError.new.message = t("preferences:errorNewPasswordRequired");
      newError.new.error = true;
    } else {
      let validPassword = await checkCurrentPassword(newPassword);
      if (validPassword) {
        newError.new.message = t(
          "preferences:newPasswordCantBeEqualToCurrentPassword",
        );
        newError.new.error = true;
      } else if (newPassword.length < 8) {
        newError.new.message = t("preferences:errorPasswordLength");
        newError.new.error = true;
      } else if (!validatePassword(newPassword)) {
        newError.new.message = t("preferences:errorPasswordMissingCharacters");
        newError.new.error = true;
      }

      if (passwords.repeat && passwords.repeat !== newPassword) {
        newError.repeat.message = t("preferences:errorPasswordsNotMatch");
        newError.repeat.error = true;
      }
    }

    setErrors(newError);
  };
  const validateRepeatPassword = (repeatPassword) => {
    let newError = {
      current: { message: errors.current.message, error: errors.current.error },
      new: { message: errors.new.message, error: errors.new.error },
      repeat: {
        message: errors.repeat.message,
        error: errors.repeat.error,
      },
    };
    if (repeatPassword === "") {
      newError.repeat.message = t("preferences:errorRepeatPasswordRequired");
      newError.repeat.error = true;
    } else if (passwords.new && passwords.new !== repeatPassword) {
      newError.repeat.message = t("preferences:errorPasswordsNotMatch");
      newError.repeat.error = true;
    } else {
      newError.repeat.message = "";
      newError.repeat.error = false;
    }

    setErrors(newError);
  };

  const handleInvalidPassword = () => {
    let newError = {
      current: { message: errors.current.message, error: errors.current.error },
      new: { message: errors.new.message, error: errors.new.error },
      repeat: {
        message: errors.repeat.message,
        error: errors.repeat.error,
      },
    };
    newError.current.message = t("preferences:errorInvalidPassword");
    newError.current.error = true;
    setErrors(newError);
  };

  const handleClickShowPassword = (password) => {
    if (password === "current") {
      setShowCurrentPassword((show) => !show);
    } else if (password === "new") {
      setShowNewPassword((show) => !show);
    } else {
      setShowRepeatPassword((show) => !show);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        maxWidth="xs"
        fullWidth
        escapeKeyDown
        onClose={handleClose}>
        <DialogTitle id="change-password-title">
          {t("preferences:changePassword")}
        </DialogTitle>
        <DialogContent>
          <FormControl
            style={{ marginBottom: "40px", marginTop: "10px" }}
            fullWidth
            error={errors.current.error}
            onChange={(e) => {
              updateCurrentPassword(e);
            }}>
            <InputLabel id="current-password-label">
              {t("preferences:currentPassword*")}
            </InputLabel>
            <OutlinedInput
              id="modal-current-password"
              autoFocus
              type={showCurrentPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="current-password-icon-visibility"
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("current")}
                    edge="end">
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText id="current-password-helper-text">
              {errors.current.message === undefined ||
              errors.current.message === ""
                ? ""
                : errors.current.message}
            </FormHelperText>
          </FormControl>

          <br></br>

          <FormControl
            fullWidth
            error={errors.new.error}
            onChange={(e) => {
              updateNewPassword(e);
            }}
            style={{ marginBottom: "10px" }}>
            <InputLabel id="new-password-label">
              {t("preferences:newPassword*")}
            </InputLabel>
            <OutlinedInput
              id="modal-new-password"
              type={showNewPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="new-password-icon-visibility"
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("new")}
                    edge="end">
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText id="new-password-helper-text">
              {errors.new.message !== "" ? errors.new.message : ""}
            </FormHelperText>
          </FormControl>

          <br></br>

          <FormControl
            fullWidth
            error={errors.repeat.error}
            style={{ marginBottom: "10px" }}
            onChange={(e) => {
              updateRepeatPassword(e);
            }}>
            <InputLabel id="repeat-password-label">
              {t("preferences:repeatPassword*")}
            </InputLabel>
            <OutlinedInput
              id="modal-repeat-password"
              type={showRepeatPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="repeat-password-icon-visibility"
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("repeat")}
                    edge="end">
                    {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText id="repeat-password-helper-text">
              {errors.repeat.message !== "" ? errors.repeat.message : ""}
            </FormHelperText>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button id="modal-close-button" onClick={handleClose}>
            {t("preferences:close")}
          </Button>
          <Button
            id="modal-save-button"
            variant="contained"
            color="primary"
            disabled={!valid}
            onClick={handleSave}>
            {t("preferences:save")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PasswordModal;
