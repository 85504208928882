import React, { useEffect, useState } from "react";
import TransferTable from "./TransferTable/TransferTable.component";
import CampaignsTable from "./CampaignsTable/CampaignsTable.component";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const providersData = [
  {
    name: "ChattigoProvider",
    value: "chattigo",
  },
  {
    name: "GupshupProvider",
    value: "gupshup",
  },
  {
    name: "MetaProvider",
    value: "meta",
  },
  {
    name: "TeamsProvider",
    value: "teams",
  },
  {
    name: "TelepromProvider",
    value: "teleprom",
  },
];

const Transfers = () => {
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "chattigoTable" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  useEffect(() => {
    getUserPermisions();
  }, []);

  return (
    <div style={{ display: isSmallScreen ? "block" : "flex" }}>
      <Box
        id="transfer-table-container"
        sx={{
          height: "100%",
          width: isSmallScreen ? "100%" : "54%",
          padding: "10px",
          borderRadius: "25px",
          backgroundColor: "containerTableBackground.backgroundColor",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}>
        <TransferTable
          providersData={providersData}
          userPermissions={userPermissions}
        />
      </Box>
      <Box
        id="campaigns-table-container"
        sx={{
          width: isSmallScreen ? "100%" : "44%",
          marginLeft: isSmallScreen ? "" : "2%",
          marginTop: isSmallScreen ? "2%" : "",
          padding: "10px",
          borderRadius: "25px",
          backgroundColor: "containerTableBackground.backgroundColor",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}>
        <CampaignsTable
          providersData={providersData}
          userPermissions={userPermissions}
        />
      </Box>
    </div>
  );
};

export default Transfers;
