import React, { Fragment, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { get } from "../http/httpService";
import ListItemText from "@mui/material/ListItemText";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

export function HolidayPicker({ ...props }) {
  const [holidays, setHolidays] = React.useState([]);
  const { t } = useTranslation("holiday");

  useEffect(() => {
    getHolidays();
  }, []);

  function getHolidays() {
    get("/holidays")
      .then(async (data) => {
        setHolidays(data.holidays);
      });
  }

  function holidaySelected(e) {
    props.holidaySelected(e.target.value);
  }

  function updateCheck() {
    props.onChecked(!props.reverse);
  }

  return (
    <Fragment>
      <FormControlLabel
        label={t("reverse")}
        control={
          <Checkbox
            disabled={props.disabled}
            checked={props.reverse}
            onChange={updateCheck}
          />
        }
      />
      <Select
        id="holiday-select"
        fullWidth
        multiple
        value={props.selected}
        disabled={props.disabled}
        renderValue={(selected) => selected.join(", ")}
        onChange={holidaySelected}>
        {holidays.map((item, index) => (
          <MenuItem
            key={index}
            value={item.name}
            selected={props.selected.indexOf(item.name) > -1}>
            <Checkbox checked={props.selected.indexOf(item.name) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </Fragment>
  );
}
