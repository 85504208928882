import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { get, post, put, remove } from "../shared/http/httpService";

import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { DeleteRowModal } from "../shared/helper/tableHelper";

import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditModal from "./Components/EditModal.component";
import NewModal from "./Components/NewModal.component";
import Permissions from "./Components/Permissions.component";

import { ThemeProvider } from "@emotion/react";
import { Chip } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useSelector } from "react-redux";
import { mkConfig, generateCsv, download } from "export-to-csv";
import dayjs from "dayjs";

import { tableDarkTheme, tableLightTheme } from "../shared/theming/table.theme";

const User = () => {
  const { t, i18n } = useTranslation("users");
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const theme = useSelector((state) => state.theme);

  const [clientList, setClientList] = useState([]);

  const sortedClientList = useMemo(
    () => [...clientList].sort((a, b) => a.name.localeCompare(b.name)),
    [clientList],
  );

  let [expandPermissions, setExpandPermissions] = React.useState(false);
  let [userPermissions, setUserPermissions] = React.useState(false);
  let [userChangePasswordPermissions, setUserChangePasswordPermissions] =
    React.useState(false);
  let auth = useSelector((state) => state.auth);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "users" && permission.fullAccess === true,
    );
    let hasExpandPermissions = user.permissions.some(
      (permission) =>
        permission.name === "permissions" && permission.fullAccess !== null,
    );
    let hasChangePasswordPermissions = user.permissions.some(
      (permission) =>
        permission.name === "passwordChange" && permission.fullAccess === true,
    );
    setExpandPermissions(hasExpandPermissions);
    setUserPermissions(hasPermissions);
    setUserChangePasswordPermissions(hasChangePasswordPermissions);
  };

  const userTypeMappings = {
    Root: t("Root"),
    User: t("User"),
    API: t("API"),
  };

  const petitionTypeFilterOptions = Object.keys(userTypeMappings).map(
    (key) => ({
      value: key,
      text: userTypeMappings[key],
    }),
  );

  const columns = useMemo(
    () => [
      {
        header: t("username"),
        accessorFn: (original) => original.username, //alternate way
        id: "username",
        size: 150,
      },
      {
        header: t("password"),
        accessorFn: (original) => original.password,
        id: "password",
        size: 150,
      },
      {
        header: t("email"),
        accessorFn: (original) => original.email,
        id: "email",
        size: 150,
      },
      {
        header: t("type"),
        accessorFn: (original) => original.type,
        id: "type",
        size: 150,
        filterVariant: "multi-select",
        filterSelectOptions: petitionTypeFilterOptions,
        Cell: ({ cell }) => userTypeMappings[cell.getValue()],
        filterFn: (row, id, filter) => {
          if (filter.length === 0) {
            return true;
          }
          return filter.includes(row.original.type);
        },
      },
      {
        header: t("lastConnectionDate"),
        accessorFn: (original) =>
          original.lastConnectionDate
            ? dayjs(original.lastConnectionDate).format("DD/MM/YYYY HH:mm")
            : "",
        id: "lastConnectionDate",
        size: 150,
      },
      {
        header: t("clients"),
        accessorFn: (original) => original.clients,
        id: "clients",
        size: 150,
        Cell: ({ cell }) => {
          let arrayClients = cell.getValue();
          let filteredClients = Array.isArray(arrayClients)
            ? arrayClients.filter((client) =>
              clientList.some((clientObj) => clientObj.name === client),
            )
            : [];
          return (
            <div>
              {filteredClients.map((client, index) => (
                <Chip key={index} label={client} variant="outlined" />
              ))}
            </div>
          );
        },
      },
      {
        header: t("userLastModified"),
        accessorFn: (original) => original.userLastModified,
        id: "userLastModified",
        size: 150,
      },
    ],
    [t, clientList],
  );

  const [data, setData] = useState([{}]);

  useEffect(() => {
    getClientNames();
    getUserPermisions();
  }, []);

  const getClientNames = () => {
    get("/clients/names")
      .then(async (data) => {
        setClientList(data);
        getUsers();
      })
      .catch(() => {
        enqueueSnackbar(t("users:errorGettingClients"), {
          variant: "error",
        });
      });
  };

  const getUsers = () => {
    get("/users")
      .then(async (data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingUser"), {
          variant: "error",
        });
      });
  };

  const newUser = (row) => {
    const body = {
      username: row.username,
      password: row.password,
      email: row.email,
      type: row.type,
      language: "ES",
      clients: row.clients,
      permissions: row.permissions,
    };
    post("/users", body)
      .then(() => {
        enqueueSnackbar(t("userAdded"), {
          variant: "success",
        });
        getUsers();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorCreatingUser"), {
          variant: "error",
        });
      });
  };

  const updateUser = (row, values) => {
    const body = {
      _id: row.original._id,
      username: values.username,
      email: values.email,
      client: values.client,
      clients: values.clients,
      lock: values.lock,
      loginRetries: !values.lock ? false : row.original.loginRetries,
      twoFactorAuth: values.twoFactorAuth,
    };

    const password = values.password;

    put("/users", body)
      .then(async () => {
        if (password && password !== "" && userChangePasswordPermissions) {
          return put("/users/forceUpdatePassword", {
            _id: row.original._id,
            username: values.username,
            password: password,
          });
        }
      })
      .then(() => {
        enqueueSnackbar(t("userUpdated"), {
          variant: "success",
        });
        getUsers();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorUpdatingUser"), {
          variant: "error",
        });
      });
  };

  const deleteUser = (row) => {
    remove("/users", { _id: row.original._id })
      .then(async () => {
        enqueueSnackbar(t("userDeleted"), {
          variant: "success",
        });
        getUsers();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorDeletingUser"), {
          variant: "error",
        });
      });
  };

  const prepareDataForExport = (data) => {
    const fileName = "darwin_users";
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = data.map((row) => {
      let userData = {
        [t("users:username")]: row.original.username,
        [t("users:email")]: row.original.email,
        [t("users:type")]: row.original.type,
        [t("users:lastConnectionDate")]: dayjs(
          row.original.lastConnectionDate,
        ).format("DD/MM/YYYY HH:mm"),
        [t("users:clients")]:
          row.original.clients && row.original.clients.length !== 0
            ? row.original.clients.join(", ")
            : "",
      };

      row.original.permissions?.forEach((permission) => {
        userData[t(`users:${permission.name}`)] = permission.fullAccess
          ? t("users:fullAccess")
          : permission.fullAccess === false
            ? t("users:readOnly")
            : t("users:noAccess");
      });
      return userData;
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const table = useMaterialReactTable({
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
      },
    },
    positionActionsColumn: "last",
    columns: columns,
    data: data,
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    editDisplayMode: "modal",
    enableEditing: true,
    onEditingRowSave: updateUser,
    enableDelete: true,
    initialState: {
      columnVisibility: {
        userLastModified: false,
        password: false,
      },
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip
          arrow
          placement="left"
          title={userPermissions ? t("editButton") : t("noPermissionTooltip")}>
          <span>
            <IconButton
              id="edit-icon"
              onClick={() => {
                setEditModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          arrow
          placement="right"
          title={
            userPermissions ? t("deleteButton") : t("noPermissionTooltip")
          }>
          <span>
            <IconButton
              id="delete-icon"
              sx={{ color: "deleteIcon.color" }}
              onClick={() => {
                setDeleteModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}>
        <Tooltip title={userPermissions ? "" : t("noTooltipPermission")}>
          <span>
            <Button
              className="mrt-create-new-account-button"
              id="new-user-button"
              onClick={() => {
                setNewModalOpen(true);
                //setTypeModal("new");
              }}
              disabled={!userPermissions}
              variant="contained">
              {t("addButton")}
            </Button>
          </span>
        </Tooltip>
        <div>
          <Button
            className="button-users-download"
            variant="contained"
            onClick={() => {
              prepareDataForExport(table.getPrePaginationRowModel().rows);
            }}
            size="medium">
            {t("conversationalInteractions:download")}
          </Button>
        </div>
      </div>
    ),
    enableExpandAll: false,
    renderDetailPanel: ({ row }) => {
      return expandPermissions ? (
        <div>
          <Permissions row={row.original} />
        </div>
      ) : (
        <p>{t("noPermissionTooltip")}</p>
      );
    },
  });

  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("users")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteUser(editRow);
          setDeleteModalOpen(false);
        }}
        title={t("tittleDeleteModal")}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        users={data}
        rowdata={{
          _id: editRow?.original?._id,
          username: editRow?.original?.username,
          password: editRow?.original?.password,
          email: editRow?.original?.email,
          type: editRow?.original?.type,
          client: editRow?.original?.client,
          clients: editRow?.original?.clients,
          lock: editRow?.original?.lock,
          twoFactorAuth: editRow?.original?.twoFactorAuth,
        }}
        onSave={(values) => {
          updateUser(editRow, values);
          setEditModalOpen(false);
        }}
        typeModal={typeModal}
        clientList={sortedClientList}
        userChangePasswordPermissions={userChangePasswordPermissions}
      />
      <NewModal
        open={newModalOpen}
        onClose={() => {
          setNewModalOpen(false);
        }}
        users={data}
        onSave={(values) => {
          newUser(values);
          setNewModalOpen(false);
        }}
        typeModal={typeModal}
        clientList={sortedClientList}
      />
    </React.Fragment>
  );
};

export default User;
