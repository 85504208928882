import React, { useState } from "react";
import { VictoryAxis, VictoryChart, VictoryTooltip, VictoryBar } from "victory";
import Paper from "@mui/material/Paper";
import { get } from "../../shared/http/httpService";
import ChartTheme from "../theme/theme";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import { mkConfig, generateCsv, download } from "export-to-csv";

import {
  channelsList,
  changeNameChannels,
} from "../../shared/helper/analyticsHelper";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { acumulateObjectTalk } from "../../shared/helper/validations";
import { useTranslation } from "react-i18next";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "center",
    display: "flex",
  },
  button: {
    height: "40px",
    width: "100px",
    backgroundColor: "#D9D9D9",
    color: "black",
    borderRadius: "5px",
    marginRight: "5px",
    marginLeft: "20px",
  },
}));

const TalkTime = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  let chartTheme = ChartTheme();
  const [valid, setValid] = useState(true);
  const [total, setTotal] = useState(0);
  const [talkTimes, setTalkTimes] = useState([]);
  const [totalTalks, setTotalTalks] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [fileName, setFileName] = useState(
    t("analytics:talkTimeFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );
  const [channelsSelected, setChannelsSelected] = useState([
    "MESSENGER",
    "WHATSAPP",
    "INSTAGRAMDIRECT",
    "WEBCHAT",
    "WEB",
    "msteams",
  ]);
  const [allChannelLabel, setAllChannelLabel] = useState(t("talktime:all"));
  const [isDeselectAll, setIsDeselectAll] = useState(false);

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((item) => {
      let channel = item[2];
      switch (item[2]) {
        case "WHATSAPP":
          channel = "WHATSAPP";
          break;
        case "WEB":
          channel = "WEB";
          break;
        case "MESSENGER":
          channel = "FACEBOOK";
          break;
        case "INSTAGRAMDIRECT":
          channel = "INSTAGRAM";
          break;
        case "msteams":
          channel = "TEAMS";
          break;
        default:
          channel = "WEBCHAT";
          break;
      }
      return {
        [t("talktime:minutes")]: item[0],
        [t("analytics:count")]: item[1],
        [t("talktime:channel")]: channel,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchTalkTimes = async () => {
    const talkTimesTmp = await get("/report/talkTime", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
      channelsSelected: channelsSelected,
    });

    const arr2 = [];
    const arr = [];

    channelsSelected.forEach((channel) => {
      const result = [];
      talkTimesTmp.map((talkTime) => {
        if (channel === talkTime[2]) {
          result.push(talkTime[1]);
          return {
            x: talkTime[1],
          };
        }
      });
      if (result.length === 0) return;
      const arrayResult = result.reduce((acum, item) => {
        // eslint-disable-next-line no-sequences
        return acum[item] ? (acum[item] += 1) : (acum[item] = 1), acum;
      }, {});
      Object.keys(arrayResult).map(function (key) {
        const object = { x: key, y: arrayResult[key] };
        arr.push(object);
        return object;
      });

      Object.keys(arrayResult).map(function (key) {
        return arr2.push([Number(key), arrayResult[key], channel]);
      });
    });

    const arrayTalkTimes = acumulateObjectTalk(arr);

    setTotalTalks(arr2.sort((a, b) => a[0] - b[0]));
    setTotal(talkTimesTmp.length);
    setTalkTimes(arrayTalkTimes.sort((a, b) => a.x - b.x));
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:talkTimeFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  function handleChannels(channels) {
    if (channels.includes(t("talktime:all"))) {
      if (channelsSelected.length < 4) {
        setChannelsSelected([
          "MESSENGER",
          "WHATSAPP",
          "INSTAGRAMDIRECT",
          "WEBCHAT",
          "WEB",
          "msteams",
        ]);
        setIsDeselectAll(false);
      } else {
        setChannelsSelected([]);
        setAllChannelLabel(t("talktime:all"));
        setIsDeselectAll(true);
      }
    } else {
      setAllChannelLabel(t("talktime:all"));
      setChannelsSelected(channels);
      setIsDeselectAll(channels.length === 0);
    }
  }

  return (
    <Container maxwidth="lg">
      <Paper
        style={{
          marginBottom: "15px",
          paddingTop: "10px",
          paddingBottom: "15px",
          paddingLeft: "5px",
        }}>
        <Typography variant="h4">{t("analytics:talkTime")}</Typography>
        <br></br>
        <Grid container justify="space-around" alignItems="center">
          <DateRange
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDate}
          />
          <FormControl
            sx={{ minWidth: 130, maxWidth: 150, margin: "0px 5px 0px 5px", position: "relative", 
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: isDeselectAll ? "red" : "2C2F88", 
                },
              },
            }}>
            <InputLabel sx={{  "&.MuiInputLabel-shrink": { color: isDeselectAll ? "red" : "inherit"} }}>
              {t("talktime:channel")}
            </InputLabel>
            <Select
              label={t("talktime:channel")}
              onChange={(e) => {
                handleChannels(e.target.value);
              }}
              multiple
              value={channelsSelected}
              renderValue={(selected) => {
                if (selected.length === 6) {
                  setAllChannelLabel(t("talktime:deselectAll"));
                  return t("talktime:all");
                } else {
                  return changeNameChannels(selected).join(", ");
                }
              }}>
              <MenuItem value={t("talktime:all")} sx={{ fontStyle: "italic" }}>
                <Checkbox
                  checked={channelsSelected.length === 6}
                  indeterminate={
                    channelsSelected.length > 0 && channelsSelected.length < 6
                  }
                />
                {allChannelLabel}
              </MenuItem>
              {channelsList.map((channel) => (
                <MenuItem value={channel.value} key={channel.value}>
                  <Checkbox
                    checked={channelsSelected.indexOf(channel.value) > -1}
                  />
                  {channel.label}
                </MenuItem>
              ))}
            </Select>
            {isDeselectAll && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "4px", 
                  minHeight: "16px",
                  width: "100%", 
                  bottom: "-18px",
                  position: "absolute", 
                  whiteSpace: "nowrap",
                }} >
                {t("talktime:thisFieldIsRequired")}
              </Typography>
            )}
          </FormControl>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginLeft: "10px",
            }}>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              size="medium"
              disabled={!valid || channelsSelected.length === 0}
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}
              onClick={fetchTalkTimes}>
              {t("talktime:fetch")}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              disabled={
                totalTalks.length === 0 || channelsSelected.length === 0
              }
              size="medium"
              onClick={() => {
                prepareDataForExport(totalTalks);
              }}
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}>
              {t("conversationPerDay:download")}
            </Button>
          </div>
        </Grid>
        <VictoryChart
          fixLabelOverlap={true}
          responsive={false}
          height={500}
          width={800}
          animate={{
            duration: 500,
            onLoad: { duration: 200 },
          }}
          theme={chartTheme}
          domainPadding={{ x: 80 }}
          padding={80}>
          <VictoryBar
            barRatio={1}
            cornerRadius={{ top: 3 }}
            style={{
              data: {
                fill: "#B474DC",
              },
              labels: {
                fontSize: 10,
                fill: "black",
              },
            }}
            labels={({ datum }) =>
              datum.y === 0
                ? "0"
                : `conversations: ${datum.y}\n Time: ${datum.x}`
            }
            alignment="middle"
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                pointerLength={0}
                flyoutStyle={{
                  stroke: "grey",
                  backgroundColor: "red",
                }}
              />
            }
            data={talkTimes}
          />
          <VictoryAxis
            label={t("talktime:conversationTime")}
            style={{
              axisLabel: { padding: 30, fontSize: 15 },
            }}
          />
          <VictoryAxis
            dependentAxis
            label={t("talktime:amountConversations")}
            style={{
              axisLabel: { padding: 50, fontSize: 15 },
            }}
          />
        </VictoryChart>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            marginTop: "-20px",
          }}>
          {t("talktime:totalConversations")}: {total}
        </Typography>
      </Paper>
    </Container>
  );
};

export default TalkTime;
