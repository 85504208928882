import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { getSmoothStepPath, EdgeLabelRenderer, BaseEdge } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

const colorsType = {
  intent: "#75AADB",
  condition: "#A100FF",
  date: "#9323B3",
  entity: "#62CA69",
  direct: "#BF0ABD",
  media: "#71A081",
};

const styles = {
  container: {
    position: "absolute",
    padding: 10,
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 700,
  },
  condition: {
    padding: 5,
  },
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
  onEdgeSelectedTab = () => {},
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  //const offsetY = sourceX < targetX ? -20 : 20;
  //esto se podria usar para cuando se produce circulos en los edges identificandolos con una propiedad anteriormente

  const containerStyle = useMemo(() => {
    let adjustmentX = 0;
    let adjustmentY = 0;
    if (data.isCircular) {
      if (sourceY < targetY) {
        adjustmentY = (sourceY - targetY) * 0.25;
        if (sourceX > targetX) {
          adjustmentX = (sourceX - targetX) * 0.25;
          adjustmentY = 0;
        }
      } else {
        adjustmentX = (sourceX - targetX) * 0.25;
        if (sourceX < targetX) {
          adjustmentY = (sourceY - targetY) * 0.25;
          adjustmentX = 0;
        }
      }
    }
    return {
      ...styles.container,
      transform: `translate(-50%, -50%) translate(${labelX + adjustmentX}px,${
        labelY + adjustmentY
      }px)`,
      pointerEvents: "all",
      cursor: "pointer",
    };
  }, [labelX, labelY, sourceY, targetY, data.isCircular]);

  const handleSelectedTab = (index) => {
    onEdgeSelectedTab(index);
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{ strokeWidth: 1.5 }}
      />
      <EdgeLabelRenderer>
        <div style={containerStyle} className="nodrag nopan">
          {data.transitions.map((condition, index) => (
            <div
              key={index}
              onDoubleClick={() => handleSelectedTab(index)}
              id={"edgeLabel" + index}
              style={{
                ...styles.condition,
                background: colorsType[condition.type],
              }}>
              {condition.label}
            </div>
          ))}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

CustomEdge.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default memo(CustomEdge);
