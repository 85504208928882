import React, { useState } from "react";
import { Card, CardContent, Fab, Button, CardMedia } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";

import styles from "../Home.module.css";
import NewScenario from "./ScenarioModal";

const ScenarioCard = ({
  scenario,
  scenarioImage,
  cardIndex,
  handleClose,
  existingScenarios,
}) => {
  const [cardVariant, setCardVariant] = useState("outlined");
  const [openEditScenario, setOpenEditScenario] = useState(false);
  const { t } = useTranslation("home");
  const navigate = useNavigate();

  // Función para determinar el mensaje a mostrar
  const getTimeMessage = (updatedAt) => {
    const updatedAtDate = new Date(updatedAt);
    const now = new Date();
    const differenceInHours = (now - updatedAtDate) / (1000 * 60 * 60);

    if (differenceInHours < 0.1) {
      // Menos de 6 minutos, aproximadamente "un momento"
      return t("updatedJustNow");
    } else if (differenceInHours < 4) {
      // Menos de 4 horas
      return t("updatedLessThanFourHours");
    } else {
      // Más antiguo
      return t("updatedOlder");
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {" "}
      <NewScenario
        id="new-scenario"
        open={openEditScenario}
        handleClose={() => {
          setOpenEditScenario(false);
          handleClose("edit");
        }}
        scenario={scenario}
        existingScenarios={existingScenarios}
      />
      <Card
        id={`card-${cardIndex}`}
        className={`${styles.scenario} ${styles[cardVariant]}`}
        variant={cardVariant}
        elevation={cardVariant === "elevated" ? 4 : 0}
        onMouseEnter={() => setCardVariant("elevated")}
        onMouseLeave={() => setCardVariant("outlined")}
        onClick={() => {
          setCardVariant("filled");
        }}>
        <div id={`card-div-${cardIndex}`}>
          <div className={`${styles.hoverButtons} ${cardVariant === "elevated" ? styles.visible : ""}`}
            style={{ 
              opacity: cardVariant === "elevated" ? 1 : 0,
              visibility: cardVariant === "elevated" ? "visible" : "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
              marginTop: "-20px!important",
            }}>                    
            <Button
              id={`go-to-scenario-${cardIndex}`}
              className={styles.hoverButton}
              onClick={() =>
                navigate("/flowgraph", {
                  state: { scenarioName: scenario.scenario.name },
                })
              }
              variant="contained"
              sx={{
                color: "#2C2F88",
                marginTop: "100px", // Asegura un espacio entre los botones
                width: "300px",
              }}>
              {t("goToScenarioButton")}
            </Button>
            <Button
              id={`edit-scenario-${cardIndex}`}
              className={styles.hoverButton}
              variant="outlined"
              onClick={() => {
                setOpenEditScenario(true);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                color: "#2C2F88",
                marginTop: "10px", // Asegura un espacio entre los botones
                width: "300px",
                "&:hover": {
                  backgroundColor: "#F8F8F8!important",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.15)!important",
                },
              }}>
              {t("editScenarioButton")}
            </Button>
          </div>
          <div className={styles.cardMedia}>
            <div className={styles.blurOverlay} style={{
              filter: cardVariant === "elevated" ? "blur(3px)" : "none",
              transition: "filter 0.3s ease",
            }}>
              <CardMedia
                style={{
                  position: "relative",
                  height: "205px",
                }}
                id={`card-media-${cardIndex}`}
                component="img"
                height={205}
                image={scenarioImage}
                title={scenario.scenario?.name}
              />
            </div>
          </div>
          <CardContent
            id={`card-content-${cardIndex}`}
            className={styles.cardContent}>
            <span
              id={`card-title-${cardIndex}`}
              className={styles.titleScenario}>
              {scenario.scenario?.display_name}
            </span>
            <span
              id={`card-description-${cardIndex}`}
              className={styles.descriptionScenario}>
              {scenario.scenario?.description || "\u00A0"}
            </span>
            <span
              id={`card-last-update-${cardIndex}`}
              className={styles.lastUpdateScenario}
              style={{ display: "flex", alignItems: "center" }}>
              {getTimeMessage(scenario.updatedAt)}
              <Fab
                id={`card-fab-${cardIndex}`}
                className={styles.fab}
                disableRipple={true}
                disableFocusRipple={true}
                sx={{
                  marginLeft: 1,
                  backgroundColor: "#3A3EB5",
                  "&:hover": {
                    backgroundColor: "#3A3EB5",
                  },
                  height: 20,
                  width: 20,
                  fontSize: 8,
                  minHeight: 0,
                  color: "#fff",
                  boxShadow: 0,
                }}>
                <span id={`card-fab-initial-${cardIndex}`}>
                  {scenario.trace?.userName.charAt(0)}
                </span>
              </Fab>
            </span>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

ScenarioCard.propTypes = {
  scenario: PropTypes.object,
  scenarioImage: PropTypes.string,
  cardIndex: PropTypes.number,
  handleClose: PropTypes.func,
  existingScenarios: PropTypes.arrayOf(PropTypes.string),
};

export default ScenarioCard;
