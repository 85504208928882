import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  validateAgentName,
  validateDisplayNameCharacters,
  validateEmpty,
  validateNameClientsCharacters,
} from "../shared/helper/validations";
import { Button } from "@mui/material";

const EditModal = ({ typeModal, data, clients, open, onClose, onSave }) => {
  //Asignacion de variables necesarias
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [defaultAgent, setDefaultAgent] = useState("");
  const [agents, setAgents] = useState([]);
  const [errors, setErrors] = useState({});

  const { t } = useTranslation("clientsModal");

  useEffect(() => {
    if (typeModal === "edit") {
      setName(data.name);
      setDisplayName(data.display_name);
      setDefaultAgent(data.default_agent);
      setAgents(data.agents);
    } else {
      setName("");
      setDisplayName("");
      setDefaultAgent("");
    }
    setErrors({});
  }, [data, typeModal]);

  //Validacion de datos en inputs
  const validateLabel = (field, label) => {
    let valid = true;
    let validateCharacter = { valid: true, message: "" };
    if (!validateEmpty(field)) {
      return { valid: false, message: t("validationEmptyLabel") };
    }
    if (label === "name") {
      valid = validateNameClientsCharacters(field);
      if (!valid) {
        validateCharacter = {
          valid: false,
          message: t("validationNameCharacters"),
        };
      }
    } else if (label === "displayName") {
      valid = validateDisplayNameCharacters(field);
      if (!valid) {
        validateCharacter = {
          valid: false,
          message: t("validationDisplayNameCharacters"),
        };
      }
    } else {
      valid = validateAgentName(field);
      if (!valid) {
        validateCharacter = {
          valid: false,
          message: t("validationDefaultAgentCharacters"),
        };
      }
    }
    return validateCharacter;
  };

  const validateName = useCallback(() => {
    const temp = {};
    if (typeModal === "new") {
      let vName = validateLabel(name, "name");
      clients.forEach((e) => {
        if (e.name === name) {
          vName = { valid: false, message: t("errorNameClienExist") };
        }
      });
      temp.name = vName.valid ? "" : t(vName.message);
    }
    setErrors({ ...errors, ...temp });
  }, [t, name]);

  const validateDisplayName = useCallback(() => {
    if (open) {
      const temp = {};
      let vDisplayName = validateLabel(displayName, "displayName");
      clients.forEach((e) => {
        if (e.display_name === displayName) {
          vDisplayName = {
            valid: false,
            message: t("errorDisplayNameClienExist"),
          };
        }
      });
      temp.displayName = vDisplayName.valid ? "" : t(vDisplayName.message);
      setErrors({ ...errors, ...temp });
    }
  }, [t, displayName]);

  const validateDefaultAgent = useCallback(() => {
    if (open) {
      const temp = {};
      const vDefaultAgent = validateLabel(defaultAgent, "defaultAgent");
      temp.defaultAgent = vDefaultAgent.valid ? "" : t(vDefaultAgent.message);
      setErrors({ ...errors, ...temp });
    }
  }, [t, defaultAgent]);

  useEffect(() => {
    validateName();
  }, [name, validateName]);

  useEffect(() => {
    validateDisplayName();
  }, [displayName, validateDisplayName]);

  useEffect(() => {
    validateDefaultAgent();
  }, [defaultAgent, validateDefaultAgent]);

  const validateSave = () => {
    if (
      !validateEmpty(name) ||
      !validateEmpty(displayName) ||
      !validateEmpty(defaultAgent)
    ) {
      return false;
    }
    return true;
  };

  //Render
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
        {typeModal === "new"
          ? t("modalTittleNew")
          : t("modalTittleEdit") + data.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          variant="standard"
          id="modal-name"
          label={t("name")}
          required
          disabled={typeModal === "edit" ? true : false}
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={name}
          error={errors.name}
          helperText={errors.name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <TextField
          variant="standard"
          id="modal-display-name"
          label={t("displayName")}
          required
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={displayName}
          error={errors.displayName}
          helperText={errors.displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
        />
        {typeModal === "edit" ? (
          <FormControl
            sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}>
            <InputLabel
              variant="standard"
              id="modal-combo-default-agent"
              required>
              {t("defaultAgent")}
            </InputLabel>
            <Select
              id="modal-combo-default-agent"
              variant="standard"
              value={defaultAgent}
              required
              error={errors.defaultAgent}
              helperText={errors.defaultAgent}
              onChange={(e) => {
                setDefaultAgent(e.target.value);
              }}>
              {agents.map((agent) => (
                <MenuItem key={agent.name} value={agent.name}>
                  {agent.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            id="modal-default-agent"
            variant="standard"
            label={t("defaultAgent")}
            required
            sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
            value={defaultAgent}
            error={errors.defaultAgent}
            helperText={errors.defaultAgent}
            onChange={(e) => {
              setDefaultAgent(e.target.value);
            }}
          />
        )}
      </Box>
      <DialogActions>
        <Button id="modal-cancel-button" onClick={onClose}>
          {t("modalCancel")}
        </Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={
            Object.keys(errors).some((x) => errors[x])
              ? true
              : !validateSave()
                ? true
                : false
          }
          onClick={() =>
            typeModal === "edit"
              ? onSave({ displayName, defaultAgent })
              : onSave({ name, displayName, defaultAgent })
          }>
          {typeModal === "edit" ? t("modalSave") : t("modalCreate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
