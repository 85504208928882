import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const ChatbotScript = ({ client }) => {
  const domainName = window.location.hostname;
  const regex = "^https://app.([a-z0-9-]+).|app.([a-z0-9-]+).";
  const subdomain = domainName.match(regex);
  const environment = subdomain
    ? subdomain[2] !== "botdarwin"
      ? subdomain[2]
      : ""
    : "local";
  const scriptSrc =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CHATBOT_SCRIPT_URL ||
    "https://darwin-chat.s3.amazonaws.com/latest/darwin-chat.js";
  const scriptArguments = {
    clientname: client.name,
    environment: environment,
  };
  const token = useSelector((state) => state.auth.token);
  const renderChat = useSelector((state) => state.client.renderChat);
  const scriptCleanup = () => {
    const hasScriptInBody = document.querySelector("body script") !== null;
    const scriptElement = document.querySelector("body script");
    if (hasScriptInBody) {
      document.body.removeChild(scriptElement);
    }
  };

  useEffect(() => {
    if (client && client.name && client.name !== "") {
      const isNotInLoginRoute = !window.location.pathname.includes("/login");
      scriptCleanup();
      if (isNotInLoginRoute) {
        const script = document.createElement("script");
        script.src = scriptSrc;
        script.async = true;
        Object.entries(scriptArguments).forEach(([key, value]) => {
          script.setAttribute(`data-${key}`, value);
        });
        document.body.appendChild(script);
      }
    }
  }, [client.name, token, renderChat]);

  return (
    <React.Fragment key={client.name + renderChat}>
      <div id="DarwinChatbot"></div>
    </React.Fragment>
  );
};

export default ChatbotScript;
