import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../shared/helper/validations";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { validatePasswordWithErrors } from "../../shared/helper/validations";

const EditModal = ({
  rowdata,
  users,
  clientList,
  open,
  onClose,
  onSave,
  userChangePasswordPermissions,
}) => {
  const { t } = useTranslation("users");
  const [username, setUsername] = useState("");
  const [_id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [lock, setLock] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [clients, setClients] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorsPassword, setErrorsPassword] = useState({});
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({});
  const [validPassword, setValidPassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (open && !isSaving) {
      setUsername(rowdata.username);
      setId(rowdata._id);
      setEmail(rowdata.email);
      setType(rowdata.type);
      let filteredClients = Array.isArray(rowdata.clients)
        ? rowdata.clients.filter((client) =>
          clientList.some((clientObj) => clientObj.name === client),
        )
        : [];
      setClients(filteredClients);
      setLock(rowdata.lock);
      setTwoFactorAuth(rowdata.twoFactorAuth);
    }
  }, [open, rowdata, clientList, isSaving]);

  const validateEmpty = (field) => {
    return field === undefined || field === "" ? false : true;
  };

  const validateEmailLocal = useCallback(
    (email) => {
      if (!validateEmpty(email)) {
        return t("validationEmailEmpty");
      }

      if (!validateEmail(email)) {
        return t("validationEmail");
      }

      if (users) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].email === email && users[i]._id !== _id) {
            return t("validationEmailAlreadyExist");
          }
        }
        return false;
      }
      return false;
    },
    [_id, t, users],
  );

  const validate = useCallback(() => {
    const temp = {};
    temp.email = validateEmailLocal(email);
    temp.clients =
      clients?.length !== 0 || type === "Root"
        ? false
        : t("validationClientsEmpty");

    setErrors(temp);
  }, [validateEmailLocal, email, t, clients, type]);

  useEffect(() => {
    validate();
  }, [email, type, clients, validate]);

  const getOptionClientLabel = useMemo(() => (option) => option.name, []);

  const valueClients = useMemo(
    () => clientList.filter((x) => clients?.includes(x.name)),
    [clientList, clients],
  );

  const handleClientsChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        let clientsNames = [];
        newValue.forEach((value) => {
          clientsNames.push(value.name);
        });
        setClients(clientsNames);
      } else {
        setClients([]);
      }
    },
    [setClients],
  );

  const handleSave = () => {
    setIsSaving(true);
    onSave({
      username,
      email,
      clients,
      type,
      lock,
      twoFactorAuth,
      password: validPassword ? password : undefined,
    });
    setTimeout(() => setIsSaving(false), 500); // ejecuta desp de 0.5 seg
    setConfirmPassword("");
    setPassword("");
  };

  const handleClose = () => {
    onClose();
    setConfirmPassword("");
    setPassword("");
  };

  const renderInputClients = useMemo(
    () =>
      function renderInputClientsTextfield(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("clients") + "*"}
            error={errors.clients}
            helperText={errors.clients}
          />
        );
      },
    [(t, errors.clients)],
  );

  useEffect(() => {
    const temp = validatePasswordWithErrors(
      password,
      confirmPassword,
      username,
    );

    const isValid = Object.values(temp).every((x) => x === true);
    setValidPassword(isValid || (password === "" && confirmPassword === ""));
    setErrorsPassword(temp);
  }, [password, confirmPassword]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ marginLeft: "1rem", marginTop: "1rem" }}>
        {t("modalTitleEdit")}
      </Typography>
      <Box
        sx={{
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "10%",
          marginRight: "10%",
          alignItems: "center",
          padding: "1rem",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            gap: "1rem",
          }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h6">
              {t("username") + ": " + username}
            </Typography>
            <Typography variant="h6">{t("type") + ": " + t(type)}</Typography>
          </Box>
          <TextField
            id="modal-email"
            variant="standard"
            label={t("email") + "*"}
            sx={{
              width: "100%",
              marginTop: "1rem",
            }}
            value={email}
            error={errors.email}
            helperText={errors.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Autocomplete
            id="modal-clients"
            multiple
            variant="standard"
            options={clientList}
            value={valueClients}
            getOptionLabel={getOptionClientLabel}
            sx={{
              width: "100%",
              marginTop: "1rem",
              display: type === "Root" ? "none" : "",
            }}
            onChange={handleClientsChange}
            renderInput={renderInputClients}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
            marginTop: "1rem",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Switch
              id="edit-modal-switch"
              name="value"
              label="Lock"
              checked={lock}
              onChange={(e) => setLock(e.target.checked)}
            />
            <Typography variant="h6">Bloqueado</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
            <Switch
              id="edit-modal-auth2fa-switch"
              name="value"
              label={t("2fa")}
              checked={twoFactorAuth}
              onChange={(e) => setTwoFactorAuth(e.target.checked)}
            />
            <Typography variant="h6">{t("twoFactorAuth")}</Typography>
          </Box>
        </Box>
        {userChangePasswordPermissions && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              marginTop: "1rem",
            }}>
            <Typography variant="h6">{t("changePassword")}</Typography>
            <TextField
              id="modal-password"
              variant="standard"
              label={t("password")}
              type="password"
              sx={{
                width: "100%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Typography
              id="password-min-length"
              variant="h7"
              color={errorsPassword.passwordLength ? "#3fb950" : "error"}>
              {(errorsPassword.passwordLength ? "✓" : "✗") +
                t("passwordLength")}
            </Typography>
            <Typography
              id="password-min-number"
              variant="h7"
              color={errorsPassword.containsNumber ? "#3fb950" : "error"}>
              {(errorsPassword.containsNumber ? "✓" : "✗") +
                t("containsNumber")}
            </Typography>
            <Typography
              id="password-uppercase"
              variant="h7"
              color={errorsPassword.containsUppercase ? "#3fb950" : "error"}>
              {(errorsPassword.containsUppercase ? "✓" : "✗") +
                t("containsUppercase")}
            </Typography>
            <Typography
              id="password-lowercase"
              variant="h7"
              color={errorsPassword.containsLowercase ? "#3fb950" : "error"}>
              {(errorsPassword.containsLowercase ? "✓" : "✗") +
                t("containsLowercase")}
            </Typography>
            <Typography
              id="password-special-char"
              variant="h7"
              color={
                errorsPassword.containsSpecialCharacter ? "#3fb950" : "error"
              }>
              {(errorsPassword.containsSpecialCharacter ? "✓" : "✗") +
                t("containsSpecialCharacter")}
            </Typography>
            <Typography
              id="password-no-username"
              variant="h7"
              color={errorsPassword.containsUsername ? "#3fb950" : "error"}>
              {(errorsPassword.containsUsername ? "✓" : "✗") +
                t("containsUsername")}
            </Typography>
            <Typography
              variant="h7"
              color={errorsPassword.passwordsMatch ? "#3fb950" : "error"}>
              {(errorsPassword.passwordsMatch ? "✓" : "✗") +
                t("passwordsMatch")}
            </Typography>
            <TextField
              id="modal-confirm-password"
              variant="standard"
              type="password"
              label={t("confirmPassword")}
              sx={{
                width: "100%",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        )}
      </Box>
      <DialogActions>
        <Button
          id="modal-cancel-button"
          onClick={handleClose}
          variant="contained"
          color="error">
          {t("modalCancel")}
        </Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={
            Object.keys(errors).some((x) => errors[x]) || !validPassword
          }
          onClick={() => handleSave()}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
