import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setTheme } from "../shared/redux/themeSlice";
import { Card, CardContent, CardMedia, Button } from "@mui/material";
import scenarioImage from "./media/scenario.png";
import documentationImage from "./media/documentation.png";
import { get } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import ScenarioCard from "./Scenarios/ScenarioCard";
import NewScenario from "./Scenarios/ScenarioModal";
import styles from "./Home.module.css";
import FabButtons from "./FabButtons/FabButtons";
import FabNewScenarioButton from "./FabButtons/FabNewScenarioButton";

const Home = ({ token }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const client = useSelector((state) => state.client);
  const [openNewScenario, setOpenNewScenario] = useState(false);
  const { t } = useTranslation("home");
  const { enqueueSnackbar } = useSnackbar();
  const [scenarios, setScenarios] = useState([]);
  const [scenariosToGet, setScenariosToGet] = useState(1);
  const [agents, setAgents] = useState([]);
  const [noAccess, setNoAccess] = useState(false);
  const [cardVariant, setCardVariant] = useState("outlined");
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  useEffect(() => {
    if (token && client.name) {
      let scenariosToGet = updateVisibleScenarios();
      setScenariosToGet(scenariosToGet);
      getLatestScenarios(scenariosToGet);
      getAllAgents();
      getUserPermisions();
    }
  }, [token, client.name]);

  useEffect(() => {
    const storedTheme =
      localStorage.getItem("theme") === "false" ? false : true;

    if (theme !== storedTheme) {
      dispatch(setTheme(storedTheme));
    }
  }, [theme, dispatch]);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions;
    for (let i in user.permissions) {
      if (user.permissions[i].name === "agents") {
        if (user.permissions[i].fullAccess === true) {
          setNoAccess(false);
          hasPermissions = true;
        } else if (user.permissions[i].fullAccess === null) {
          setNoAccess(true);
          hasPermissions = false;
        } else {
          setNoAccess(false);
          hasPermissions = false;
        }
      }
    }
    setUserPermissions(hasPermissions);
  };

  const getLatestScenarios = (scenariosToGet) => {
    scenariosToGet = scenariosToGet === 0 ? 1 : scenariosToGet;
    get("/traces/getLatestScenarios?scenariosToGet=" + scenariosToGet)
      .then(async (data) => {
        setScenarios(data);
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorGettingScenarios "), {
          variant: "error",
        });
      });
  };

  const getAllAgents = () => {
    get("/agents")
      .then(async (data) => {
        setAgents(data.agents);
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorGettingAgents"), {
          variant: "error",
        });
      });
  };

  const handleClose = (action) => {
    setOpenNewScenario(false);
    if (action === "edit") {
      getLatestScenarios(scenariosToGet);
    }
  };

  const updateVisibleScenarios = () => {
    const containerWidth = window.innerWidth; // Ancho de la ventana
    const cardWidth = 400; // Ancho fijo de cada tarjeta
    const gapWidth = 16; // Ancho del gap entre tarjetas
    const maxCards = Math.floor(
      (containerWidth + gapWidth) / (cardWidth + gapWidth),
    ); // Número máximo de tarjetas que caben
    return maxCards;
  };

  return (
    <div className={styles.container} id="home">
      <FabButtons id="fab-buttons"></FabButtons>
      <NewScenario
        id="new-scenario"
        open={openNewScenario}
        handleClose={(mode, scenariosCounter) =>
          handleClose(mode, scenariosCounter)
        }
        existingScenarios={agents.map((agent) => agent.display_name)}
      />
      <div className={styles.homeBackground} id="home-background">
        <div className={styles.header} id="header">
          <span className={styles.headline} id="recent-scenarios-title">
            {t("recentScenariosTitle")}
          </span>
          {!noAccess ? (
            <FabNewScenarioButton
              id="fab-new-scenario-button"
              setOpenNewScenario={setOpenNewScenario}
              userPermissions={userPermissions}></FabNewScenarioButton>
          ) : null}
        </div>

        <div className={styles.scenarios} id="scenarios-list">
          {scenarios.length > 0
            ? scenarios.map((scenario, index) => (
              <ScenarioCard
                key={index}
                cardIndex={index}
                id={`scenario-card-${index}`}
                scenario={scenario}
                scenarioImage={scenarioImage}
                handleClose={handleClose}
                existingScenarios={agents.map((agent) => agent.display_name)}
              />
            ))
            : ""}
        </div>
        <span className={styles.subtitle} id="learn-now-title">
          {t("learnNowTitle")}
        </span>
        <div className={styles.divider} id="divider"></div>
        <div className={styles.scenarios} id="documentation-card-container">
          <Card
            className={`${styles.documentationCard} ${styles[cardVariant]}`}
            id="documentation-card"
            variant={cardVariant}
            elevation={cardVariant === "elevated" ? 4 : 0}
            onMouseEnter={() => setCardVariant("elevated")}
            onMouseLeave={() => setCardVariant("outlined")}>
            <div className={styles.hoverButtons}>
              <Button
                id="documentation-button"
                className={styles.hoverButton}
                onClick={() =>
                  window.open(
                    "https://evoltis.atlassian.net/wiki/spaces/DAR/overview",
                    "_blank",
                    "noopener noreferrer",
                  )
                }
                variant="contained"
                sx={{
                  color: "#2C2F88",
                  marginTop: "100px", // Asegura un espacio entre los botones
                  width: "300px",
                }}>
                {t("goToDocumentationButton")}
              </Button>
            </div>
            <div className={styles.blurOverlay}>
              <CardMedia
                component="img"
                height="220"
                image={documentationImage}
                title="Contemplative Reptile"
                id="documentation-image"></CardMedia>
            </div>
            <CardContent
              className={styles.cardContent}
              id="documentation-content">
              <span className={styles.titleScenario} id="user-manual-title">
                {t("userManualTitle")}
              </span>
              <span
                className={styles.descriptionScenario}
                id="user-manual-description">
                {t("userManualdescription")}
              </span>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme.darkTheme,
});
export default connect(mapStateToProps)(Home);
