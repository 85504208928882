/* eslint-disable indent */
const Moment = require("moment");

// Function that validates that there are no repeated questions in any topic
export const validateQuestion = (data, question, name_intent) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].name !== name_intent) {
      for (let j = 0; j < data[i].phrases.length; j++) {
        for (let x = 0; x < question.length; x++) {
          if (data[i].phrases[j] === question[x]) {
            return { message: "false", nombre: question[x] };
          }
        }
      }
    }
    continue;
  }
  return true;
};

/*Function that validates that it begins with an alphanumeric character
(uppercase, lowercase or accented vowels) and continues
with any combination of alphanumeric characters, spaces and hyphens */
export const validateCharacters = (field, allowedSpecialCharacters = "") => {
  // escape special characters from extraChars to include them in the regex
  let escapedExtraChars = allowedSpecialCharacters.replace(
    /[-[\]{}()*+?.,\\^$|#\s]/g,
    "\\$&",
  );
  let regex = new RegExp(
    `^[0-9a-zA-ZÑñ\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xFC\xDC${escapedExtraChars}]+[0-9a-zA-ZÑñ\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xFC\xDC ${escapedExtraChars}_-]*$`,
  );
  return !regex.test(field)
    ? false
    : field === undefined
    ? false
    : field === ""
    ? false
    : field === null
    ? false
    : field.length === 0
    ? false
    : true;
};

// Function that validates if the chain is empty
export const validateEmpty = (field) => {
  return field === undefined
    ? false
    : field === ""
    ? false
    : field === null
    ? false
    : field.length === 0
    ? false
    : true;
};

/*Function that validates only alphanumeric characters (numbers, uppercase, lowercase or accented vowels) and periods*/
export const validateOnlyCharacters = (field) => {
  let regex = new RegExp(
    "^[0-9a-zA-ZÑñ.\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xFC\xDC]*$",
  );
  return regex.test(field);
};

// Function that validates caracters in lowercase, numbers and dashes
export const validateLowerAndNumbers = (field) => {
  let regex = new RegExp("^[a-zñ0-9\xE1\xE9\xED\xF3\xFA\xFC_-]+$");
  return regex.test(field);
};

// Function that validates caracters in uppercase, lowercase, numbers, spaces and dashes
export const validateDisplayNameCharacters = (field) => {
  let regex = new RegExp("^[0-9a-zA-ZÑñáéíóúÁÉÍÓÚüÜ _-]{4,24}$");
  return regex.test(field);
};

// Function that validates a text with emojis and special caracters
export const validateTextWithEmoji = (field) => {
  let regex = new RegExp(
    "^(([0-9a-zA-ZÑñ\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xFC\xDC])" + //first letters and numbers
      "|(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])" + //emojis
      "|([¿¡]))" + //question mark and exclamation mark
      "+(([0-9a-zA-ZÑñ\xE1\xE9\xED\xF3\xFA\xC1\xC9\xCD\xD3\xDA\xFC\xDC])" + //next letters and numbers
      "|(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])" + //emojis
      "|([ .,;:¿?/¡!@#$%&*()_=+-]))*$",
  ); //special characters
  return !regex.test(field) ? false : true;
};

// Function that validates between 4 and 24 characters with uppercase, lowercase and hyphens
export const validateNameClientsCharacters = (field) => {
  let regex = new RegExp("^[A-Za-z_-]{4,24}$");

  return !regex.test(field) ? false : true;
};

// Function that validates between 4 and 24 characters with lowercase, numbers, spaces and hyphens
export const validateNameCharacters = (field) => {
  let regex = new RegExp("^[0-9a-zA-ZÑñáéíóúÁÉÍÓÚüÜ _-]{4,24}$");
  return regex.test(field);
};

export const validateNumbersCharacters = (string, number = 0) => {
  let count = 0;
  let filtro = "0123456789";
  for (let i = 0; i < string.length; i++) {
    if (filtro.indexOf(string.charAt(i)) !== -1) {
      count += 1;
    }
  }
  if (count > number) {
    return false;
  }
  return true;
};

/* Funcion que valida que contenga un protocolo (http://, https://, etc) 
y seguido o no de un puerto (:port) */
export function validateUrlHost(field) {
  let hostRegex = new RegExp(
    "^([a-zA-z]+://)?(([a-zA-z.0-9-]+[:]{1}[0-9]+)||([a-zA-z.0-9-]+[.]{1}[a-zA-z.0-9-]+))$",
  );
  return hostRegex.test(field) ? true : false;
}

// Function to validate that it contains only white spaces
export const validateOnlySpace = (field) => {
  let regex = new RegExp("^([ ]+)$");
  return !regex.test(field) ? true : false;
};

/* Function that validates if it starts with "/" and continues with alphanumeric characters,
dashes, slashes and special characters */
export function validateUrlRoute(field) {
  let routeRegex = new RegExp("^/[a-zA-Z0-9-_/#$%=&?.,]*$");
  return routeRegex.test(field) ? true : false;
}

/* Function that validates that it contains one or more characters that are
  lowercase letters, digits and hyphens */
export function validateAgentName(field) {
  let agentNameRegex = new RegExp("^[a-z0-9_-]{4,24}$");
  return !agentNameRegex.test(field)
    ? false
    : field === undefined
    ? false
    : field === ""
    ? false
    : field === null
    ? false
    : field.length === 0
    ? false
    : true;
}

export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export function withinRange(conditions) {
  const found = conditions.find(
    (element) =>
      element.op === "time" &&
      (element.value.startTime === null ||
        element.value.endTime === null ||
        Moment(element.value.startTime, "HH:mm a") >
          Moment(element.value.endTime, "HH:mm a")),
  );
  if (found !== undefined) {
    return false;
  }
  return true;
}

export function validateConditions(conditions) {
  if (!conditions) {
    return true;
  }

  for (let i = 0; i < conditions.length; i++) {
    console.log(conditions[i]);
    if (conditions[i].reply === "" || conditions[i].reply === undefined) {
      return false;
    }
  }
  return true;
}

export function acumulateObject(object_array) {
  let new_array = [];
  for (let i = 0; i < object_array.length; i++) {
    let object = object_array[i];
    let count = object.y;
    for (let j = i + 1; j < object_array.length; j++) {
      if (object.x === object_array[j].x) {
        count = count + object_array[j].y;
      }
    }
    if (new_array.length > 0) {
      const resultado = new_array.some((elemento) => elemento.x === object.x);

      if (!resultado) {
        new_array.push({
          x: object.x,
          y: count,
          label: `${count} convesation/s on the day: ${object.x}`,
        });
      }
    } else {
      new_array.push({
        x: object.x,
        y: count,
        label: `${count} convesation/s on the day: ${object.x}`,
      });
    }
  }
  const sorted = new_array.sort((a, b) => Date.parse(a.x) - Date.parse(b.x));
  return sorted;
}

export function acumulateObjectTalk(object_array) {
  let new_array = [];
  for (let i = 0; i < object_array.length; i++) {
    let object = object_array[i];
    let count = object.y;
    for (let j = i + 1; j < object_array.length; j++) {
      if (object.x === object_array[j].x) {
        count = count + object_array[j].y;
      }
    }
    if (new_array.length > 0) {
      const resultado = new_array.some((elemento) => elemento.x === object.x);

      if (!resultado) {
        new_array.push({
          x: object.x,
          y: count,
        });
      }
    } else {
      new_array.push({
        x: object.x,
        y: count,
      });
    }
  }
  return new_array;
}

export function validateEmail(email) {
  let regex = new RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  );
  return regex.test(email) ? true : false;
}

export function validatePassword(password) {
  const regex = new RegExp(
    "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\\W)|(?=.*_))^[^ ]+$",
  );
  return regex.test(password) ? true : false;
}

export function newObjectId() {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const objectId =
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, () => {
        return Math.floor(Math.random() * 16).toString(16);
      })
      .toLowerCase();

  return objectId;
}

export function validatePasswordWithErrors(
  password,
  confirmPassword,
  username,
) {
  const temp = {
    containsNumber: /\d/.test(password),
    containsUppercase: /[A-Z]/.test(password),
    containsLowercase: /[a-z]/.test(password),
    containsSpecialCharacter: /[^A-Za-z0-9]/.test(password),
    containsUsername:
      password.trim().length > 0 &&
      !password.toLowerCase().includes(username.toLowerCase()),
    passwordsMatch: password === confirmPassword && confirmPassword !== "",
    passwordLength: password.length >= 8,
  };

  return temp;
}

export default validateQuestion;
