import { React, useState, useEffect } from "react";
import { Box, Tab, Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EdgeModal from "./EdgeModal.component";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function a11yProps(index) {
  return {
    id: `edgeModal-tab-${index}`,
    "aria-controls": `edgeModal-tabpanel-${index}`,
  };
}

export default function EdgeModalTabsPanel({
  open,
  onClose,
  editEdgeProps,
  previous,
  onSave,
  onDelete,
  intents,
  edges,
  pages,
  userPermissions,
  propsSelectedTab,
  onDeleteTab,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [transitions, setTransitions] = useState([]);
  const [editEdge, setEditEdge] = useState({ data: {} });
  const [editTransition, setEditTransition] = useState({});

  useEffect(() => {
    if (!editEdge?.data) {
      return;
    }
    if (
      editEdge.data.transitions &&
      !editEdge.data?.transitions?.[selectedTab]
    ) {
      createNewTransition();
    }
    if (editEdge.data.transitions?.[selectedTab]) {
      setEditTransition(editEdge.data.transitions[selectedTab]);
    }
  }, [editEdge, selectedTab]);

  const createNewTransition = () => {
    // create condition data for new transition
    const baseConditionData = {
      is_new: true,
      label: intents[0]?.name || "",
      message: "",
      oid: "",
      type: "intent",
      conditions: [],
      intents: [intents[0]?.name || ""],
      entity: {},
      cleanParams: [],
      presets: [],
      sourceId: editEdge.source.id,
      targetId: editEdge.target.id,
      sourceName: editEdge.source.data.label,
      targetName: editEdge.target.data.label,
      labelX:
        editEdge.source.position.x +
        (editEdge.target.position.x - editEdge.source.position.x) / 2,
      labelY:
        editEdge.source.position.y +
        (editEdge.target.position.y - editEdge.source.position.y) / 2,
    };
    // create a new transition for the edge
    let tempEditEdge = { ...editEdge };
    tempEditEdge.data.transitions.push(baseConditionData);
    setTransitions(tempEditEdge.data.transitions);
  };

  useEffect(() => {
    if (Object.keys(editEdgeProps?.data).length === 0) {
      return;
    }
    let tempSelectedTab = propsSelectedTab ? propsSelectedTab : 0;
    setEditEdge(editEdgeProps);
    setTransitions(editEdgeProps.data.transitions);
    setSelectedTab(tempSelectedTab);
  }, [open]);

  const handleChange = (event, newselectedTab) => {
    setSelectedTab(newselectedTab);
  };

  const handleClose = () => {
    setEditEdge({ data: {} });
    onClose();
  };

  // Get the transition data in the format required by traces
  const getTransition = (editEdge, index) => {
    let condition = editEdge.data.transitions[index];
    let change = editEdge;
    return {
      animated: condition.animated,
      type: change.type,
      data: {
        ...condition,
      },
      id: index === 0 ? change.id : `${change.id} - ${index + 1}`,
      is_new: condition.is_new,
      is_modified: condition.is_modified,
      source: change.source,
      target: change.target,
    };
  };

  const handleDeleteTransition = (index) => {
    let tempEditTransition = getTransition(editEdge, index);

    if (editEdge.data.transitions.length !== 1) {
      let tempEditEdge = { ...editEdge };
      tempEditEdge.data.transitions.splice(index, 1);
      let newIndex =
        index === tempEditEdge.data.transitions.length ? index - 1 : index;
      setTransitions(tempEditEdge.data.transitions);
      setSelectedTab(newIndex);
      onDeleteTab(tempEditTransition, tempEditEdge);
    } else {
      onDelete(tempEditTransition);
    }
  };

  const onSaveEdge = (editTransition) => {
    let tempEditEdge = { ...editEdge };
    tempEditEdge.data.transitions[selectedTab] = editTransition;

    setEditEdge({ data: {} });
    setEditTransition({});

    onSave(tempEditEdge);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "900px",
          position: "absolute",
          top: 30,
          height: "auto",
          overflow: "auto",
        },
      }}>
      <Box>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable">
              {transitions?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={index}
                    label={transitions[index].label}
                    {...a11yProps(index)}></Tab>
                );
              })}
              <Tab
                key={transitions.length}
                value={transitions.length}
                icon={<AddIcon />}></Tab>
            </TabList>
          </Box>
          {transitions?.map((item, index) => {
            return (
              <TabPanel key={index} value={index} sx={{ padding: 0 }}>
                <div
                  style={{ display: index === selectedTab ? "block" : "none" }}>
                  <EdgeModal
                    onClose={handleClose}
                    editTransition={editTransition}
                    editEdge={editEdge}
                    previous={previous}
                    onSave={onSaveEdge}
                    onDelete={handleDeleteTransition}
                    intents={intents}
                    edges={edges}
                    pages={pages}
                    transitions={edges}
                    userPermissions={userPermissions}
                    index={index}
                    selectedTab={selectedTab}
                  />
                </div>
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </Dialog>
  );
}
