import React, { useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function EdgePresets({ ...props }) {
  const [presets, setPresets] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [dirty, setDirty] = React.useState({});
  const { t } = useTranslation("edge");

  useEffect(() => {
    if (Array.isArray(props.presets) && props.presets.length !== 0) {
      setPresets(props.presets);
    }
  }, [props.presets]);

  useEffect(() => {
    const updatePresets = async () => {
      const valid = await validate();
      props.presetsUpdate(presets, valid);
    };
    updatePresets();
  }, [presets]);

  function handleAddPreset() {
    let current = [...presets];
    current.push({ prop: "", value: "" });
    setPresets(current);
  }
  const validate = useCallback(() => {
    const tempErrors = {};
    const tempDirty = { ...dirty };

    presets.forEach((item, index) => {
      tempErrors[index] = {};
      tempErrors[index].prop =
        item.prop !== "" && item.prop !== undefined
          ? ""
          : t("modalErrorPropRequired");
      tempErrors[index].value =
        item.value !== "" && item.value !== undefined
          ? ""
          : t("modalErrorValueRequired");

      if (!tempDirty[index]?.prop && item.prop !== "") {
        tempDirty[index] = { ...tempDirty[index], prop: true };
      }

      if (!tempDirty[index]?.value && item.value !== "") {
        tempDirty[index] = { ...tempDirty[index], value: true };
      }
    });

    setDirty(tempDirty);
    setErrors(tempErrors);

    // Verificar si hay errores
    const hasErrors = Object.values(tempErrors).some(
      (error) => error.prop !== "" || error.value !== "",
    );

    return !hasErrors;
  }, [presets, dirty, t]);

  function handleRemovePreset() {
    if (presets.length > 0) {
      let current = [...presets];
      current.pop({});
      setPresets(current);
    }
  }

  function handleIsParamsCheck(e, index) {
    if (presets.length) {
      let current = [...presets];
      current[index].itsParams = e.target.checked;
      setPresets(current);
    }
  }

  function updateName(e, index) {
    if (presets.length) {
      let current = [...presets];
      current[index].prop = e.target.value;
      setPresets(current);
    }
  }

  function updateValue(e, index) {
    if (presets.length) {
      let current = [...presets];
      current[index].value = e.target.value;
      setPresets(current);
    }
  }

  return (
    <div className="preset">
      <h3 className="preset-title">{t("presets")}</h3>
      <div className="preset-buttons">
        <Tooltip
          title={
            !props.userPermissions
              ? t("noPermissionTooltip")
              : t("tooltipPresetsAdd")
          }>
          <span>
            <IconButton
              id="add-preset"
              disabled={!props.userPermissions}
              onClick={handleAddPreset}
              style={{ cursor: "pointer" }}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            !props.userPermissions
              ? t("noPermissionTooltip")
              : t("tooltipPresetsDelete")
          }>
          <span>
            <IconButton
              id="remove-preset"
              disabled={!props.userPermissions}
              onClick={handleRemovePreset}
              style={{ cursor: "pointer" }}>
              <RemoveIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {presets?.map((item, index) => (
        <div key={toString(index)} className="preset-header">
          <h3 className="preset-title">
            {t("preset")} {index + 1}
          </h3>
          <Tooltip title={!props.userPermissions ? "noPermissionTooltip" : ""}>
            <TextField
              id="preset-name"
              className="preset-name"
              label={t("presetName")}
              variant="standard"
              multiline
              disabled={!props.userPermissions}
              fullWidth
              defaultValue={item.prop ? item.prop : ""}
              onChange={(e) => updateName(e, index)}
              error={errors[index]?.prop && dirty[index]?.prop}
              helperText={
                errors[index]?.prop && dirty[index]?.prop
                  ? errors[index]?.prop
                  : ""
              }
            />
          </Tooltip>
          <Tooltip title={!props.userPermissions ? "noPermissionTooltip" : ""}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}>
              <TextField
                id="preset-value"
                className="preset-value"
                label={t("presetValue")}
                variant="standard"
                multiline
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item.value ? item.value : ""}
                onChange={(e) => updateValue(e, index)}
                error={errors[index]?.value !== "" && dirty[index]?.value}
                helperText={
                  errors[index]?.value && dirty[index]?.value
                    ? errors[index]?.value
                    : ""
                }
              />
              <div
                style={{
                  display: "flex",
                  width: "30%",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "10px",
                }}>
                <Checkbox
                  checked={item.itsParams}
                  onChange={(e) => handleIsParamsCheck(e, index)}
                  size="small"
                />
                <span style={{ fontSize: "12px" }}>{t("itsAParams")}</span>
              </div>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

EdgePresets.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      prop: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  presetsUpdate: PropTypes.func.isRequired,
  userPermissions: PropTypes.bool.isRequired,
};
