import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Tooltip,
} from "@mui/material/";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import "../../../Flowgraph/Flowgraph.css";

import EdgeIntents from "../../../Flowgraph/Components/EdgeComponents/edge.intents.component";
import EdgeConditions from "../../../Flowgraph/Components//EdgeComponents/edge.conditions.component";
import EdgeEntity from "../../../Flowgraph/Components//EdgeComponents/edge.entity.component";
import EdgeDateConditions from "../../../Flowgraph/Components/EdgeComponents/edge.date.conditions.component";
import EdgeCleanParams from "../../../Flowgraph/Components//EdgeComponents/edge.clean.params.component";
import EdgePresets from "../../../Flowgraph/Components//EdgeComponents/edge.presets.component";
import EdgeMedia from "../../../Flowgraph/Components/EdgeComponents/edge.media.component";

const EdgeModal = ({ ...props }) => {
  const [type, setType] = React.useState("intent");
  const [message, setMessage] = React.useState("");
  const [intents, setIntents] = React.useState([]);
  const [conditions, setConditions] = React.useState([]);
  const [entity, setEntity] = React.useState({});
  const [media, setMedia] = React.useState({});
  const [presets, setPresets] = React.useState([]);
  const [cleanParams, setCleanParams] = React.useState([]);
  const [valid, setValid] = React.useState(false);
  const [editEdge, setEditEdge] = React.useState({});
  const [error, setError] = React.useState("");
  const { t } = useTranslation();
  const [userPermissions, setUserPermissions] = useState(false);
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ?? 0);
  const [index, setIndex] = useState(props.index ?? 0);

  useEffect(() => {
    setUserPermissions(props.userPermissions);
    setEditEdge(props.editTransition);
    setError("");
    setType(props.editTransition.type);
    setMessage(props.editTransition.message);
    setIntents(props.editTransition.intents);
    setConditions(cloneDeep(props.editTransition.conditions));
    setEntity(props.editTransition.entity);
    setMedia(props.editTransition.media);
    setPresets(props.editTransition.presets);
    setCleanParams(props.editTransition.cleanParams);
  }, [props.editTransition]);

  useEffect(() => {
    if (Object.keys(editEdge).length !== 0) {
      setSelectedTab(props.selectedTab);
      setIndex(props.index);
    }
  }, [editEdge]);

  useEffect(() => {
    // Validate correctly in case of direct transition, after type update
    if (type === "direct") {
      let valid = validateSameTransition();
      if (!valid) {
        setError(t("flowgraph:errorSameTransition"));
      } else {
        setError("");
      }
      setValid(valid);
    }
  }, [type]);

  const handleChangeType = (e) => {
    let value = e.target.value;
    if (value === "intent") {
      setIntents([props.intents[0].name]);
      setConditions([]);
    } else if (value === "condition") {
      setConditions([
        {
          op: "eq",
        },
      ]);
    } else if (value === "date") {
      setConditions([
        {
          op: "time",
          value: {
            startTime: dayjs().startOf("day").format("HH:mm:ss"),
            endTime: dayjs().endOf("day").format("HH:mm:ss"),
          },
        },
      ]);
    } else if (value === "direct") {
      setValid(true);
      setConditions([]);
    }
    setType(value);
  };

  const updateMessage = (e) => {
    setMessage(e.target.value);
  };

  const updateIntents = (update) => {
    let validIntents;
    setIntents(update);
    if (update.length > 0) {
      setValid(true);
      validIntents = validateSameTransition(update);
      if (!validIntents) {
        setError(t("flowgraph:errorSameTransition"));
        setValid(false);
      } else {
        setError("");
      }
    } else {
      setValid(false);
      setError("");
    }
  };

  const updateConditions = (update, valid) => {
    if (valid) {
      setConditions(update);
    }
    if (validateSameTransition(update)) {
      setError("");
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  };

  const updateEntity = (update, valid) => {
    setEntity(update);
    if (validateSameTransition(entity)) {
      setError("");
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  };

  const updateMedia = (update, valid) => {
    setMedia(update);
    if (validateSameTransition(media)) {
      setError("");
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  };

  const updateDateConditions = (update, valid) => {
    updateConditions(update);
    if (validateSameTransition(update)) {
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  };

  const updatePresets = (update, valid) => {
    setValid(valid);
    setPresets(update);
  };

  const updateCleanParams = (cleanParams) => {
    setCleanParams(cleanParams);
  };

  const conditionOperations = (operation) => {
    let name = "";
    switch (operation) {
      case "eq":
        name = " =";
        break;
      case "neq":
        name = " !=";
        break;
      case "lt":
        name = " <";
        break;
      case "elt":
        name = " <=";
        break;
      case "gt":
        name = " >";
        break;
      case "egt":
        name = " >=";
        break;
      case "cgt":
        name = ".length >=";
        break;
      case "clt":
        name = ".length <=";
        break;
    }
    return name;
  };

  const conditionDates = (date) => {
    let name = "";
    let operator;
    switch (date.op) {
      case "eq":
        name = "=";
        break;
      case "neq":
        name = "!=";
        break;
      case "time":
        name =
          date.value.startTime.slice(0, 5) +
          " hs - " +
          date.value.endTime.slice(0, 5) +
          " hs";
        break;
      case "date":
        name =
          dayjs(date.value.startDate).format("MM/DD/YYYY") +
          " < 🗓️ " +
          " < " +
          dayjs(date.value.endDate).format("MM/DD/YYYY");
        break;
      case "day":
        name = date.value.join(" | ");
        break;
      case "holiday":
        operator = date.reverse ? "!" : "=";
        name = "Holiday 🗓️ " + operator + "= " + date.value.join(" | ");
        break;
      case "schedule":
        name = date.reverse ? "Out of Schedule" : "Schedule";
        break;
    }
    return name;
  };

  //Validates if there is an equal transition but with a different destination node
  const validateSameTransition = (change) => {
    let valid = true;
    let edges = props.transitions;
    let conflictTransitions = [];
    edges.forEach((edge) => {
      if (edge.source === props.editEdge?.source.id) {
        edge.data.transitions.forEach((transition, index) => {
          const isSameTransition =
            edge.target === props.editEdge?.target.id && index === selectedTab;
          if (transition.type === type && !isSameTransition) {
            conflictTransitions.push(transition);
          }
        });
      }
    });
    if (conflictTransitions.length > 0) {
      for (let j in conflictTransitions) {
        switch (type) {
          case "intent": {
            let existIntent = conflictTransitions[j].intents?.some((e) =>
              change.includes(e),
            );
            if (existIntent) {
              return false;
            }
            break;
          }
          case "condition": {
            let existCondition = conflictTransitions[j].conditions?.filter(
              (e) =>
                change.some(
                  (n) =>
                    e.op === n.op && e.prop === n.prop && e.value === n.value,
                ),
            );
            if (existCondition?.length === change.length) {
              return false;
            }
            break;
          }
          case "entity": {
            if (
              conflictTransitions[j].entity.value === change?.value ||
              conflictTransitions[j].entity.name === change?.name
            ) {
              return false;
            }
            break;
          }
          case "media": {
            if (conflictTransitions[j].media.name === change?.name) {
              return false;
            }
            break;
          }
          case "date": {
            let conditions = conflictTransitions[j].conditions;
            if (valid) {
              valid = validateSameDate(change, conditions);
            }
            break;
          }
          case "direct": {
            return false;
          }
          default:
            valid = true;
            setError("");
            break;
        }
      }
    }
    return valid;
  };

  // Transforms the holidays array into an array of objects with a single holiday
  const transformHolidays = (array) => {
    let tempArray = [];

    array?.forEach((item) => {
      if (item.op === "holiday" && item.value.length > 1) {
        item.value.forEach((holiday) => {
          tempArray.push({
            op: item.op,
            reverse: item.reverse,
            value: [holiday],
          });
        });
      } else {
        tempArray.push(item);
      }
    });

    return tempArray;
  };

  //Validates if there is a date match
  const validateSameDate = (changes, conditions) => {
    let invalidConditions = [];
    let isHoliday = false;
    let tempChanges = transformHolidays(changes);
    let tempConditions = transformHolidays(conditions);

    for (const change of tempChanges) {
      const changeValue = change.value;
      for (const condition of tempConditions) {
        if (change.op === condition.op) {
          switch (change.op) {
            case "time": {
              const conditionValue = condition.value;
              const changeStart = moment(changeValue.startTime, "HH:mm:ss");
              const changeEnd = moment(changeValue.endTime, "HH:mm:ss");
              const conditionStart = moment(
                conditionValue.startTime,
                "HH:mm:ss",
              );
              const conditionEnd = moment(conditionValue.endTime, "HH:mm:ss");
              if (
                changeStart.isSame(conditionStart) &&
                changeEnd.isSame(conditionEnd)
              ) {
                invalidConditions.push(condition);
              }
              if (
                changeStart.isBetween(conditionStart, conditionEnd) ||
                changeEnd.isBetween(conditionStart, conditionEnd) ||
                conditionStart.isBetween(changeStart, changeEnd) ||
                conditionEnd.isBetween(changeStart, changeEnd)
              ) {
                invalidConditions.push(condition);
              }
              break;
            }
            case "date": {
              const conditionValue = condition.value;
              const changeStartDate = moment(
                changeValue.startDate.toISOString(),
              ).startOf("day");
              const changeEndDate = moment(
                changeValue.endDate.toISOString(),
              ).endOf("day");
              const conditionStartDate = moment(
                conditionValue.startDate,
              ).startOf("day");
              const conditionEndDate = moment(conditionValue.endDate).endOf(
                "day",
              );

              if (
                (changeStartDate.isSameOrBefore(conditionStartDate, "day") &&
                  changeEndDate.isSameOrAfter(conditionStartDate, "day")) ||
                (conditionStartDate.isSameOrBefore(changeStartDate, "day") &&
                  conditionEndDate.isSameOrAfter(changeStartDate, "day"))
              ) {
                invalidConditions.push(condition);
              }
              break;
            }
            case "day": {
              let valid = true;
              const conditionValue = condition.value;
              for (let i = 0; i < 7; i++) {
                if (changeValue[i] !== "" || conditionValue[i] !== "") {
                  if (changeValue[i] === conditionValue[i]) {
                    valid = false;
                    break;
                  }
                }
              }
              if (!valid) {
                invalidConditions.push(condition);
              }
              break;
            }
            case "holiday": {
              const conditionValue = condition.value;
              const holidays = changeValue.some((holiday) =>
                conditionValue.includes(holiday),
              );
              if (holidays && change.reverse === condition.reverse) {
                invalidConditions.push(condition);
                isHoliday = true;
              }
              break;
            }
            case "schedule": {
              if (change.reverse === condition.reverse) {
                invalidConditions.push(condition);
              }
              break;
            }
          }
        }
      }
    }
    return !(
      invalidConditions.length === tempConditions.length &&
      (invalidConditions.length === tempChanges.length || isHoliday)
    );
  };

  const setLabel = (tempEditTransition) => {
    switch (tempEditTransition.type) {
      case "intent":
        tempEditTransition.label = tempEditTransition.intents.join(", ");

        break;

      case "condition":
        if (tempEditTransition.conditions.length > 0) {
          tempEditTransition.label = tempEditTransition.conditions
            .map((condition) => {
              return (
                condition.prop +
                conditionOperations(condition.op) +
                " " +
                condition.value
              );
            })
            .join(", ");
        }
        break;

      case "entity":
        tempEditTransition.label = "Entity: " + tempEditTransition.entity.name;
        break;

      case "media":
        tempEditTransition.label = "Media: " + tempEditTransition.media.name;
        break;

      case "date":
        if (tempEditTransition.conditions.length > 0) {
          tempEditTransition.label = tempEditTransition.conditions
            .map((condition) => {
              return conditionDates(condition);
            })
            .join(", ");
        }
        break;

      case "direct":
        tempEditTransition.label = "Direct Transition";
        break;

      default:
        tempEditTransition.label = "No valid transition";
        break;
    }
    return tempEditTransition;
  };

  const handleSave = () => {
    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.type = type;
    tempEditTransition.message = message;
    tempEditTransition.intents = intents;
    tempEditTransition.conditions = conditions;
    tempEditTransition.entity = entity;
    tempEditTransition.media = media;
    tempEditTransition.cleanParams = cleanParams;
    tempEditTransition.presets = presets;

    // Validate if the edge has been modified
    Object.keys(tempEditTransition).forEach((key) => {
      if (typeof tempEditTransition[key] === "object") {
        if (
          JSON.stringify(tempEditTransition[key]) !==
          JSON.stringify(props.editTransition[key])
        ) {
          tempEditTransition.is_modified = true;
        }
      } else if (tempEditTransition[key] !== props.editTransition[key]) {
        tempEditTransition.is_modified = true;
      }
    });

    // Set the transition label
    tempEditTransition = setLabel(tempEditTransition);

    setType("");
    setEditEdge({});

    props.onSave(tempEditTransition);
  };

  const handleClose = () => {
    setMessage("");
    setIntents([]);
    setConditions([]);
    props.onClose();
  };

  const handleDelete = () => {
    props.onDelete(props.index);
    setType("");
  };

  return (
    <div hidden={selectedTab !== index}>
      {selectedTab === index && (
        <div>
          <DialogTitle>
            <Typography
              sx={{
                display: "inline-flex",
                float: "right",
                fontSize: "0.7rem",
              }}>
              {props.editEdge?.source?.data?.label}
              <ChevronRightIcon sx={{ fontSize: "0.9rem" }} />
              {props.editEdge?.target?.data?.label}
            </Typography>
            <Typography style={{ display: "inline-flex" }}>
              {t("flowgraph:transitionEdit")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <RadioGroup
              aria-label="entityType"
              name="entityType"
              value={type ?? "intent"}
              onChange={handleChangeType}>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="intent"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:intent")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="condition"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:condition")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="entity"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:entity")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="date"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:date")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="direct"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:direct")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="media"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:media")}
                />
              </Tooltip>
            </RadioGroup>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <TextField
                id="transition-message"
                label={t("flowgraph:message")}
                variant="standard"
                className="transition-message"
                multiline
                disabled={!userPermissions}
                fullWidth
                defaultValue={message}
                onChange={updateMessage}
              />
            </Tooltip>
            {type === "intent" ? (
              <EdgeIntents
                userPermissions={userPermissions}
                list={props.intents}
                intents={intents}
                intentsUpdate={updateIntents}
              />
            ) : null}
            {type === "condition" ? (
              <EdgeConditions
                userPermissions={userPermissions}
                conditions={conditions}
                conditionsUpdate={updateConditions}
              />
            ) : null}
            {type === "entity" ? (
              <EdgeEntity
                userPermissions={userPermissions}
                entity={entity}
                entityUpdate={updateEntity}
                pages={props.pages}
              />
            ) : null}
            {type === "date" ? (
              <EdgeDateConditions
                userPermissions={userPermissions}
                dateConditions={conditions}
                dateConditionsUpdate={updateDateConditions}
                edges={props.edges}
                source={editEdge.source}
                id={editEdge.id}
              />
            ) : null}
            {type === "media" ? (
              <EdgeMedia
                userPermissions={userPermissions}
                media={media}
                mediaUpdate={updateMedia}
              />
            ) : null}
            {error !== "" ? (
              <Typography
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "3%",
                }}>
                {error}
              </Typography>
            ) : null}
            <EdgeCleanParams
              userPermissions={userPermissions}
              cleanParams={cleanParams}
              cleanParamsUpdate={updateCleanParams}
            />
            <EdgePresets
              userPermissions={userPermissions}
              presets={presets}
              presetsUpdate={updatePresets}
            />
          </DialogContent>
          <DialogActions>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
              <span>
                <Button
                  id="edge-modal-delete"
                  variant="contained"
                  disabled={!userPermissions}
                  onClick={handleDelete}>
                  {t("flowgraph:delete")}
                </Button>
              </span>
            </Tooltip>

            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
              <span>
                <Button
                  id="edge-modal-save"
                  variant="contained"
                  disabled={!valid || !userPermissions}
                  onClick={handleSave}>
                  {t("flowgraph:save")}
                </Button>
              </span>
            </Tooltip>
            <Button
              id="edge-modal-close"
              variant="contained"
              onClick={handleClose}>
              {t("flowgraph:close")}
            </Button>
          </DialogActions>
        </div>
      )}
    </div>
  );
};

EdgeModal.propTypes = {
  userPermissions: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number,
  index: PropTypes.number,
  edges: PropTypes.array.isRequired,
  intents: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  editTransition: PropTypes.object.isRequired,
  editEdge: PropTypes.object.isRequired,
  transitions: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired, // Añadir la validación de onDelete
};

export default EdgeModal;
