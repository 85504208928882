import React, { useState, useMemo } from "react";
import { get } from "../../shared/http/httpService";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";

import {
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTooltip,
} from "victory";
import { useTheme } from "@mui/system";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";

import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "center",
    display: "flex",
  },
  button: {
    height: "40px",
    width: "100px",
    backgroundColor: "#D9D9D9",
    color: "black",
    borderRadius: "5px",
    marginRight: "5px",
    marginLeft: "20px",
  },
}));

const RankingIntents = () => {
  let theme = useTheme();
  const { t, i18n } = useTranslation();
  const [valid, setValid] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const classes = useStyles();
  const [fileName, setFileName] = useState(
    t("analytics:intentsRankingFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );
  const [graphicData, setGraphicData] = useState([]);
  const [legendData, setLegendData] = useState([]);

  const columns = useMemo(() => [
    { header: t("intentsRanking:intent"), accessorKey: "_id" },
    { header: t("intentsRanking:count"), accessorKey: "count" },
    { header: t("intentsRanking:agent"), accessorKey: "agent" },
  ]);

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((row) => {
      return {
        [t("intentsRanking:intent")]: row.original._id,
        [t("intentsRanking:count")]: row.original.count,
        [t("intentsRanking:agent")]: row.original.agent,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchRanking = async () => {
    const data = await get("/report/intents", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
    });
    const newArray = data.conversations.filter(
      (item) => item._id !== "None" && item._id !== "Default Welcome Intent",
    );

    setTableData(newArray);

    let graphicData = newArray.map((intent) => ({
      y: intent.count,
      label: intent._id + " (" + intent.agent + ")",
      agent: intent.agent,
      name: intent._id,
    }));
    let legendData = newArray.map((intent) => ({
      name: intent._id + " (" + intent.agent + ")",
    }));
    setGraphicData(graphicData);
    setLegendData(legendData);
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);

      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:intentsRankingFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    className: classes.zIndex0,
  });

  return (
    <Container maxwidth="lg">
      <div>
        <Paper
          style={{
            marginBottom: "15px",
            paddingTop: "10px",
            paddingBottom: "15px",
          }}>
          <Typography variant="h4">
            {t("intentsRanking:rankingIntents")}
          </Typography>
          <br></br>
          <Grid container justify="space-around">
            <DateRange
              startDate={startDate}
              endDate={endDate}
              onDateSelected={handleDate}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginLeft: "10px",
                alignItems: "center",
                marginTop: "10px",
              }}>
              <Button
                className={classes.button}
                variant="contained"
                color="default"
                size="medium"
                disabled={!valid}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                onClick={fetchRanking}>
                {t("intentsRanking:fetch")}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="default"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() => {
                  prepareDataForExport(table.getPrePaginationRowModel().rows);
                }}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                size="medium">
                {t("intentsRanking:download")}
              </Button>
            </div>
          </Grid>
          <VictoryPie
            labelComponent={<VictoryTooltip />}
            style={{
              labels: { fill: "black", fontSize: 5, fontWeight: "bold" },
            }}
            height={250}
            colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
            data={graphicData}
          />
          <VictoryLegend
            height={legendData.length * 15}
            orientation="horizontal"
            itemsPerRow={1}
            title={t("intentsRanking:references") + ":"}
            gutter={20}
            style={{
              labels: {
                fill: theme.palette.textColor,
                fontSize: 6,
                fontWeight: "bold",
                wordWrap: "break-word",
                textAlign: "center",
              },
              title: { fill: theme.palette.textColor, textAlign: "center" },
            }}
            titleComponent={
              <VictoryLabel style={[{ fontSize: 12 }, { fontSize: 12 }]} />
            }
            colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
            data={legendData}
          />
        </Paper>
        <Paper>
          <div style={{ maxWidth: "100%" }}>
            <ThemeProvider
              theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
              <MaterialReactTable table={table} />
            </ThemeProvider>
          </div>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
            }}>
            Total {t("intentsRanking:intents")}: {tableData.length}
          </Typography>
        </Paper>
      </div>
    </Container>
  );
};
export default RankingIntents;
