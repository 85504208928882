import React, { useEffect, useState, useMemo } from "react";
import { get, post, put } from "../../shared/http/httpService";
import Typography from "@mui/material/Typography";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import { Button, Paper, Dialog } from "@mui/material";
import { styled } from "@mui/system";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";
import { useSelector } from "react-redux";

import {
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTooltip,
} from "victory";
import Transition from "../../shared/helper/transitionDialog";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import {
  channelsList,
  changeNameChannels,
} from "../../shared/helper/analyticsHelper";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { Chip } from "@mui/material";

import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";

const useStyles = styled(() => ({
  buttonContainer: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    backgroundColor: "#D9D9D9",
    color: "black",
    height: "40px",
    width: "100px",
    borderRadius: "5px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

const RankingTags = () => {
  let theme = useTheme();
  const { t, i18n } = useTranslation();
  const [valid, setValid] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [fileName, setFileName] = useState(
    t("analytics:tagsRankingFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );
  const classes = useStyles();
  const [tagName, setTagName] = useState("");
  const [categoryTagName, setCategoryTagName] = useState([]);
  const [graphicData, setGraphicData] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEditTag, setOpenEditTag] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [errorTagName, setErrorTagName] = useState(false);
  const [errorCategoryTagName, setErrorCategoryTagName] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [channelsSelected, setChannelsSelected] = useState([
    "MESSENGER",
    "WHATSAPP",
    "INSTAGRAMDIRECT",
    "WEBCHAT",
    "WEB",
    "msteams",
  ]);
  const [allChannelLabel, setAllChannelLabel] = useState(t("rankingTags:all"));
  const { enqueueSnackbar } = useSnackbar();
  const [isDeselectAll, setIsDeselectAll] = useState(false);

  const columns = useMemo(() => [
    { header: "Tag", accessorKey: "name" },
    { header: t("rankingTags:count"), accessorKey: "count" },
    {
      header: t("rankingTags:channel"),
      accessorKey: "channel",
    },
    {
      header: t("rankingTags:category"),
      accessorKey: "category_names",
      Cell: ({ cell }) => {
        let arrayCategorys = cell.getValue();
        return (
          <div>
            {arrayCategorys?.map((category, index) => (
              <Chip key={index} label={category} variant="outlined" />
            ))}
          </div>
        );
      },
    },
  ]);

  let [userPermissions, setUserPermissions] = React.useState(false);
  let [userPermissionsCategory, setUserPermissionsCategory] =
    React.useState(false);

  let auth = useSelector((state) => state.auth);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "tags" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  const getUserPermisionsCategory = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "categoryTags" && permission.fullAccess === true,
    );
    setUserPermissionsCategory(hasPermissions);
  };

  useEffect(() => {
    fetchCategories();
    fetchTags();
    getUserPermisions();
    getUserPermisionsCategory();
  }, []);

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((row) => {
      let channelName = row.original.channel;
      if (row.original.channel === "MESSENGER") {
        channelName = "FACEBOOK";
      } else if (row.channel === "INSTAGRAMDIRECT") {
        channelName = "INSTAGRAM";
      } else if (row.channel === "msteams") {
        channelName = "TEAMS";
      }
      return {
        [t("rankingTags:name")]: row.original.name,
        [t("rankingTags:count")]: row.original.count,
        [t("rankingTags:chanelName")]: channelName,
        [t("rankingTags:category")]: row.original.category_names
          ? row.original.category_names.join(", ")
          : "",
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchCategories = async () => {
    const categories = await get("/categoryTags", undefined, true);
    const data = categories?.categoryTags?.sort(sortTags);
    setCategories(data);
  };

  const fetchTags = async () => {
    const tags = await get("/tags", undefined, true);
    const data = tags?.tags?.sort(sortTags);
    setTags(data);
  };

  const fetchTagsRanking = async () => {
    const data = await get("/report/tags", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
      channelsSelected: channelsSelected,
    }, true);
    data.tags.forEach((tag) => {
      if (tag.channel === "MESSENGER") {
        tag.channel = "FACEBOOK";
      }
      if (tag.channel === "INSTAGRAMDIRECT") {
        tag.channel = "INSTAGRAM";
      }
      if (tag.channel === "msteams") {
        tag.channel = "TEAMS";
      }
    });
    setTableData(data.tags);

    let graphicData = data.tags.map((tag) => ({
      y: tag.count,
      label: tag.name + ": " + tag.count,
    }));
    let legendData = data.tags.map((tag) => ({
      name: tag.name,
    }));
    setGraphicData(graphicData);
    setLegendData(legendData);
  };

  const sortTags = (x, y) => {
    if (x.name.toLowerCase() < y.name.toLowerCase()) {
      return -1;
    }
    if (x.name.toLowerCase() > y.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const defaultValueCategory = (newValue) => {
    if (newValue) {
      let categoriss = [];
      let defaultValue = [];
      tags.forEach((tag) => {
        if (newValue._id === tag._id) {
          if (tag.category && tag.category.length > 0) {
            tag.category.forEach((id_category) => {
              categoriss.push(id_category);
            });
          }
        }
      });
      if (categories && categories.length > 0) {
        categoriss.forEach((categoris) => {
          categories.forEach((categorie) => {
            if (categoris === categorie._id) {
              defaultValue.push(categorie);
            }
          });
        });
      }

      return setCategoryTagName(defaultValue);
    }
    return setCategoryTagName([]);
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:tagsRankingFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  const newTags = async () => {
    if (tagName === "") {
      setErrorTagName(true);
      setErrorMsg(t("tagsRanking:tagNameRequired"));
    } else {
      let newtag = {
        name: tagName,
        date: new Date(),
        category: categoryTagName,
      };
      try {
        await post("/tags", newtag);
        setOpen(false);
        setTagName("");
        setErrorTagName(false);
        setCategoryTagName([]);
        setErrorMsg("");
        fetchTags();
        enqueueSnackbar(t("tagsRanking:tagAdded"), { variant: "success" });
      } catch (error) {
        setErrorTagName(true);
        setErrorMsg("tagsRanking:tagNameAlreadyExist");
      }
    }
  };

  const updateTags = async () => {
    if (tagName === "") {
      setErrorTagName(true);
      setErrorMsg(t("tagsRanking:tagNameRequired"));
    } else {
      let updateTag = {
        _id: tagName,
        category_id: categoryTagName,
      };
      try {
        await put("/tags", updateTag);
        setOpenEditTag(false);
        setTagName("");
        setCategoryTagName("");
        setErrorTagName(false);
        setErrorMsg("");
        fetchTags();
        enqueueSnackbar(t("tagsRanking:tagUpdated"), { variant: "success" });
      } catch (error) {
        setErrorTagName(true);
        setErrorMsg(t("tagsRanking:tagNameAlreadyExist"));
      }
    }
  };

  const newCategoryTags = async () => {
    if (categoryTagName.length === 0) {
      setErrorCategoryTagName(true);
      setErrorMsg(t("tagsRanking:categoryTagNameRequired"));
    } else {
      let isTagInCategories = categories.some(
        (category) => category.name === categoryTagName,
      );
      if (!isTagInCategories) {
        let newCategory = {
          name: categoryTagName,
        };
        try {
          await post("/categoryTags", newCategory);
          setOpenCategory(false);
          setCategoryTagName("");
          setErrorCategoryTagName(false);
          setErrorMsg("");
          fetchCategories();
          enqueueSnackbar(t("tagsRanking:categoryAdded"), {
            variant: "success",
          });
        } catch (error) {
          setErrorCategoryTagName(true);
          setErrorMsg(t("tagsRanking:categoryTagNameAlreadyExist"));
        }
      } else {
        setErrorCategoryTagName(true);
        setErrorMsg(t("tagsRanking:categoryTagNameAlreadyExist"));
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEditTag(false);
    setTagName("");
    setErrorTagName(false);
    setOpenCategory(false);
    setCategoryTagName("");
    setErrorCategoryTagName("");
  };

  const changeTagName = (e) => {
    setTagName(e.target.value);
    setErrorTagName(false);
    setErrorMsg("");
  };

  const changeEditTagName = (e, newValue) => {
    newValue === null ? setTagName("") : setTagName(newValue._id);
    setErrorTagName(false);
    setErrorMsg("");
  };

  const updateCategory = (e, newValue) => {
    newValue === null ? setCategoryTagName([]) : setCategoryTagName(newValue);
  };

  const changeCategoryTagName = (e) => {
    setCategoryTagName(e.target.value);
    setErrorCategoryTagName(false);
    setErrorMsg("");
  };

  function handleChannels(channels) {
    if (channels.includes(t("rankingTags:all"))) {
      if (channelsSelected.length < 6) {
        setChannelsSelected([
          "MESSENGER",
          "WHATSAPP",
          "INSTAGRAMDIRECT",
          "WEBCHAT",
          "WEB",
          "msteams",
        ]);
        setIsDeselectAll(false);
      } else {
        setChannelsSelected([]);
        setAllChannelLabel(t("rankingTags:all"));
        setIsDeselectAll(true);
      }
    } else {
      setAllChannelLabel(t("rankingTags:all"));
      setChannelsSelected(channels);
      setIsDeselectAll(channels.length === 0);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    options: {
      maxBodyHeight: "67vh",
      minBodyHeight: "67vh",
      headerStyle: {
        zIndex: 0,
      },
    },
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    renderTopToolbarCustomActions: () => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <h2>Total Tags: {tableData.length} </h2>
        <div
          style={{
            display: "flex",
            marginLeft: "10px",
            marginTop: "auto",
            marginBottom: "auto",
            gap: "10px",
          }}>
          <Tooltip
            arrow
            placement="right"
            title={userPermissions ? "" : t("rankingTags:noPermissionTooltip")}>
            <span>
              <Button
                id="ranking-tags-button-new-tag"
                className={classes.button}
                variant="contained"
                color="primary"
                size="medium"
                disabled={!userPermissions}
                sx={{ marginBottom: "10px", marginTop: "10px" }}
                onClick={() => {
                  setOpen(true);
                }}>
                {t("rankingTags:newTag")}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            arrow
            placement="right"
            title={userPermissions ? "" : t("rankingTags:noPermissionTooltip")}>
            <span>
              <Button
                id="ranking-tags-button-edit-tag"
                className={classes.button}
                variant="contained"
                color="primary"
                size="medium"
                disabled={!userPermissions}
                sx={{ marginBottom: "10px", marginTop: "10px" }}
                onClick={() => {
                  setOpenEditTag(true);
                }}>
                {t("rankingTags:editTag")}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            arrow
            placement="right"
            title={
              userPermissionsCategory
                ? ""
                : t("rankingTags:noPermissionTooltip")
            }>
            <span>
              <Button
                id="ranking-tags-button-new-category"
                className={classes.button}
                variant="contained"
                color="primary"
                size="medium"
                disabled={!userPermissionsCategory}
                sx={{ marginBottom: "10px", marginTop: "10px" }}
                onClick={() => {
                  setOpenCategory(true);
                }}>
                {t("rankingTags:newCategory")}
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    ),
  });

  return (
    <div style={{ width: "90vw" }}>
      <div>
        <Paper
          style={{
            marginBottom: "15px",
            paddingTop: "10px",
            paddingBottom: "15px",
            borderRadius: "15px",
          }}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item xs={12} md={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}>
                <Typography variant="h4" sx={{ marginLeft: "2vh" }}>
                  {t("rankingTags:tagsRanking")}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div
                style={{
                  display: "flex",
                  marginTop: "auto",
                  marginBottom: "auto",
                  alignItems: "center",
                  justifyContent: "end",
                  marginRight: "10px",
                }}>
                <DateRange
                  startDate={startDate}
                  endDate={endDate}
                  onDateSelected={handleDate}
                />
                <FormControl sx={{ minWidth: 130, margin: "0px 5px 0px 20px", position: "relative", 
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: isDeselectAll ? "red" : "2C2F88", 
                    },
                  },
                }}>
                  <InputLabel sx={{  "&.MuiInputLabel-shrink": { color: isDeselectAll ? "red" : "inherit"} }}>
                    {t("rankingTags:channel")}
                  </InputLabel>
                  <Select
                    label={t("rankingTags:channel")}
                    onChange={(e) => {
                      handleChannels(e.target.value);
                    }}
                    multiple
                    value={channelsSelected}
                    defaultValue={[
                      "MESSENGER",
                      "WHATSAPP",
                      "INSTAGRAMDIRECT",
                      "WEBCHAT",
                      "WEB",
                      "msteams",
                    ]}
                    renderValue={(selected) => {
                      if (selected.length === 6) {
                        setAllChannelLabel(t("rankingTags:deselectAll"));
                        return t("rankingTags:all");
                      } else {
                        return changeNameChannels(selected).join(", ");
                      }
                    }}>
                    <MenuItem
                      value={t("rankingTags:all")}
                      sx={{ fontStyle: "italic" }}>
                      <Checkbox
                        checked={channelsSelected.length === 6}
                        indeterminate={
                          channelsSelected.length > 0 &&
                          channelsSelected.length < 6
                        }
                      />
                      {allChannelLabel}
                    </MenuItem>
                    {channelsList.map((channel) => (
                      <MenuItem value={channel.value} key={channel.value}>
                        <Checkbox
                          checked={channelsSelected.indexOf(channel.value) > -1}
                        />
                        {channel.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {isDeselectAll && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "4px", 
                        minHeight: "16px",
                        width: "100%", 
                        bottom: "-18px",
                        position: "absolute", 
                        whiteSpace: "nowrap",
                      }} >
                      {t("rankingTags:thisFieldIsRequired")}
                    </Typography>
                  )}
                </FormControl>
              </div>
            </Grid>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: { xs: "column", md: "row" },
                marginLeft: "10px",
              }}>
              <Button
                id="ranking-tags-button-fetch"
                className={classes.button}
                variant="contained"
                color="default"
                size="medium"
                disabled={!valid || channelsSelected.length === 0}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                onClick={fetchTagsRanking}>
                {t("rankingTags:fetch")}
              </Button>
              <Button
                id="ranking-tags-button-download"
                className={classes.button}
                variant="contained"
                color="default"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() => {
                  prepareDataForExport(table.getPrePaginationRowModel().rows);
                }}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                size="medium">
                {t("rankingTags:download")}
              </Button>
            </div>
          </Grid>
        </Paper>
      </div>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: "background.paper",
                borderRadius: "15px",
              }}>
              <ThemeProvider
                theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
                <MaterialReactTable table={table} />
              </ThemeProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                height: "50vh",
                backgroundColor: "boxAnalytics.background",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
              }}>
              <VictoryPie
                labelComponent={<VictoryTooltip />}
                style={{
                  labels: { fill: "black", fontSize: 10, fontWeight: "bold" },
                }}
                height={350}
                innerRadius={85}
                colorScale={[
                  "#004c6d",
                  "#255e7e",
                  "#3d708f",
                  "#5383a1",
                  "#6996b3",
                  "#7faac6",
                  "#94bed9",
                  "#abd2ec",
                  "#c1e7ff",
                ]}
                data={graphicData}
              />
            </Box>
            <Box
              sx={{
                height: "28vh",
                backgroundColor: "boxAnalytics.background",
                borderRadius: "15px",
                marginTop: "2vh",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
              }}>
              <VictoryLegend
                height={200}
                orientation="vertical"
                itemsPerRow={2}
                title={t("rankingTags:references") + ":"}
                gutter={20}
                style={{
                  labels: {
                    fill: theme.palette.textColor,
                    fontSize: 12,
                    fontWeight: "bold",
                  },
                  title: { fill: theme.palette.textColor },
                }}
                titleComponent={
                  <VictoryLabel style={[{ fontSize: 18 }, { fontSize: 18 }]} />
                }
                colorScale={[
                  "#004c6d",
                  "#255e7e",
                  "#3d708f",
                  "#5383a1",
                  "#6996b3",
                  "#7faac6",
                  "#94bed9",
                  "#abd2ec",
                  "#c1e7ff",
                ]}
                data={legendData}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth={true}>
        <DialogTitle>{t("rankingTags:newTag")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("tagsRanking:pleaseEnterTagName")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name-tag"
            label={t("tagsRanking:name")}
            type="text"
            fullWidth
            onChange={(e) => changeTagName(e)}
            helperText={errorTagName ? errorMsg : ""}
            error={errorTagName}
          />
          <Autocomplete
            id="category-tags"
            multiple
            options={categories ? categories : []}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              updateCategory(event, newValue);
            }}
            renderInput={(params) => (
              <Box display="flex" flexDirection="row">
                <TextField
                  {...params}
                  label={t("rankingTags:categoryTags")}
                  variant="standard"
                  className="transition-message"
                  fullWidth></TextField>
              </Box>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button id="button-new-tag-cancel" onClick={() => handleClose()}>
            {t("rankingTags:cancel")}
          </Button>
          <Button id="button-new-tag-create" onClick={() => newTags()}>
            {t("rankingTags:create")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={openEditTag}
        onClose={() => setOpenEditTag(false)}
        maxWidth="sm"
        fullWidth={true}>
        <DialogTitle>{t("rankingTags:editTag")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("rankingTags:pleaseSelectTagEdit")}
          </DialogContentText>
          <Autocomplete
            fullWidth
            style={{ marginTop: "20px", marginBottom: "20px" }}
            onChange={(event, newValue) => {
              changeEditTagName(event, newValue);
              defaultValueCategory(newValue);
            }}
            options={tags ? tags : []}
            error={errorTagName}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("rankingTgs:searchAnTag")}
                variant="outlined"
                fullWidth={true}
                helperText={errorTagName ? errorMsg : ""}
              />
            )}
          />
          <Autocomplete
            disabled={tagName === undefined || tagName === ""}
            multiple
            options={categories ? categories : []}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            getOptionLabel={(option) => option.name}
            value={categoryTagName ? categoryTagName : []}
            onChange={(event, newValue) => {
              updateCategory(event, newValue);
            }}
            renderInput={(params) => (
              <Box display="flex" flexDirection="row">
                <TextField
                  {...params}
                  label={t("rankingTags:categoriesTags")}
                  variant="standard"
                  className="transition-message"
                  fullWidth></TextField>
              </Box>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            onClick={() => updateTags()}
            disabled={categoryTagName === "" || categoryTagName.length === 0}>
            {t("rankingTags:edit")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={openCategory}
        onClose={() => setOpenCategory(false)}
        maxWidth="sm"
        fullWidth={true}>
        <DialogTitle>{t("rankingTags:newCategoryTag")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("rankingTags:pleaseEnterCategoryNewTag")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name-category"
            label={t("rankingTags:name")}
            type="text"
            fullWidth
            onChange={(e) => changeCategoryTagName(e)}
            helperText={errorCategoryTagName ? errorMsg : ""}
            error={errorCategoryTagName}
          />
        </DialogContent>
        <DialogActions>
          <Button id="button-new-category-cancel" onClick={() => handleClose()}>
            {t("rankingTags:cancel")}
          </Button>
          <Button
            id="button-new-category-create"
            onClick={() => newCategoryTags()}>
            {t("rankingTags:create")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RankingTags;
