import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { post, put } from "../shared/http/httpService";

import { validateQuestion, stringToSlug } from "../shared/helper/validations";

import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ChipInput from "../shared/components/ChipInput";

const NewIntentModal = ({
  open,
  onClose,
  onSave,
  intent,
  intents,
  enqueueSnackbar,
  agent,
  data,
}) => {
  const [name, setName] = useState("");
  const [buttonSaveHasBeenClicked, setButtonSaveHasBeenClicked] =
    useState(false);
  const [phrases, setPhrases] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [errors, setErrors] = useState({});
  const [olderName, setOlderName] = useState("");
  const [newPhrases, setNewPhrases] = useState([]);
  const [allPhrases, setAllPhrases] = useState([]);
  const [edit, setEdit] = useState(false);
  const [_id, setId] = useState("");
  const [oneWord, setOneWord] = useState(false);
  const [excludeAlternatives, setExcludeAlternatives] = useState(false);
  const [excludeRankings, setExcludeRankings] = useState(false);
  const [suggestion_message, setSuggestion_message] = useState("");
  const [responseAnimated, setResponseAnimated] = useState("");
  const { t } = useTranslation("nomatch");

  useEffect(() => {
    if (intent) {
      setName(intent.question);
      setPhrases([intent.question]);
      setNewPhrases([intent.question]);
      setAllPhrases([intent.question]);
    }
    setAnswer([]);
    setButtonSaveHasBeenClicked(false);
  }, [intent]);

  useEffect(() => {
    validate();
  }, [name, phrases]);

  const updateName = (e) => {
    setName(e.target.value);
  };

  const updatePhrases = (e) => {
    setPhrases(e);
  };

  const updateAnswer = (e) => {
    setAnswer(e);
  };

  const handleClose = () => {
    setName("");
    setPhrases([]);
    setNewPhrases([]);
    setAllPhrases([]);
    setAnswer([]);
    setEdit(false);
    setId("");

    onClose();
  };

  const validate = () => {
    let errors = {};

    if (phrases.length === 0) {
      errors.phrases = t("modalErrorQuestionRequired");
    }

    const resultValidateQuestion = validateQuestion(
      intents,
      phrases,
      name,
      edit,
    );

    if (resultValidateQuestion.message === "false") {
      errors.phrases = (
        <Trans>
          {t("nomatch:modalErrorQuestionAlredyExist", {
            question: resultValidateQuestion.nombre,
          })}
        </Trans>
      );
    }

    if (edit) {
      setErrors(errors);
      return;
    }

    const newName = name;

    if (newName === "") {
      errors.name = t("modalErrorNameIsRequired");
    }

    const index = intents.findIndex(
      (item) => item.name === stringToSlug(newName),
    );

    if (index !== -1) {
      errors.name = (
        <Trans>
          {t("modalErrorNameAlredyExist", {
            name: name,
          })}
        </Trans>
      );
    }

    setErrors(errors);
  };

  const handleSave = async () => {
    const body = {
      name,
      phrases,
      response: { text: answer },
      agent,
    };
    const currentIntent = intent;
    setButtonSaveHasBeenClicked(true);
    if (!edit) {
      await post("/intents", body)
        .then(() => {
          enqueueSnackbar(t("modalMesaggeIntentAdded"), {
            variant: "success",
          });
          updateNoMatch(currentIntent);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage.message, {
            variant: "error",
          });
        });
    } else {
      body._id = _id;
      body.oneWord = oneWord;
      body.excludeAlternatives = excludeAlternatives;
      body.excludeRankings = excludeRankings;
      body.suggestion_message = suggestion_message;
      body.responseAnimated = responseAnimated;
      await put("/intents", body)
        .then(() => {
          enqueueSnackbar("Intent updated", { variant: "success" });
          updateNoMatch(currentIntent);
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage.message, { variant: "error" });
        });
    }

    onSave();
  };

  const updateIntent = async (e, newValue) => {
    setOlderName(name);
    if (!newValue || newValue === "") {
      setName(olderName);
      setPhrases([].concat(allPhrases));
      setAnswer([]);
      setId("");
      setEdit(false);
      setOneWord(false);
      setExcludeAlternatives(false);
      setExcludeRankings(false);
      setSuggestion_message("");
      setResponseAnimated("");
    } else {
      let questions = [].concat(newValue.phrases);
      let answers = [].concat(newValue.response.text);
      setName(newValue.name);
      setPhrases(questions.concat(newPhrases));
      setAnswer(answers);
      setId(newValue._id);
      setEdit(true);
      setOneWord(newValue.oneWord);
      setExcludeAlternatives(false);
      setExcludeRankings(false);
      setSuggestion_message(newValue.excludeAlternatives);
      setResponseAnimated(newValue.responseAnimated);
    }
    validate();
  };

  const updateNoMatch = async (selectedIntent) => {
    const noMatchs = data;
    const editNoMatch = noMatchs.filter(
      (intent) => intent._id === selectedIntent._id,
    );
    const body = {
      _id: editNoMatch[0]._id,
      createdIntent: true,
    };
    await put("/noMatch", body).catch(() => {
      enqueueSnackbar(t("modalMesaggeErrorNoMatchUpdate"), {
        variant: "error",
      });
    });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {!edit && <DialogTitle>{t("createNewIntentFromNoMatch")}</DialogTitle>}
        {edit && <DialogTitle>{t("updateIntentFromNoMatch")}</DialogTitle>}

        <DialogContent>
          <Autocomplete
            id="modal-search-intent"
            fullWidth
            disablePortal
            style={{ marginTop: "20px", marginBottom: "20px" }}
            onChange={(event, newValue) => {
              updateIntent(event, newValue);
            }}
            options={intents}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("searchAnIntent")}
                variant="outlined"
              />
            )}
          />
          <TextField
            id="modal-new-intent-name"
            label={!edit ? t("newIntentName") : t("oldIntentName")}
            variant="standard"
            fullWidth
            value={name}
            disabled={edit}
            onChange={updateName}
            error={!!errors.name}
            helperText={errors.name}
          />
          <br></br>
          <ChipInput
            name="phrases"
            label={t("nomatch:questions")}
            fullWidth
            value={phrases}
            updateProps={updatePhrases}
            error={!!errors.phrases}
            helperText={errors.phrases}
          />
          {errors.phrases && (
            <Typography variant="caption" color="error">
              {errors.phrases}
            </Typography>
          )}
          <br></br>
          <ChipInput
            name="answer"
            label={t("answer")}
            fullWidth
            value={answer}
            updateProps={updateAnswer}
          />
          <br></br>
        </DialogContent>
        <DialogActions>
          <Button
            id="modal-close-button"
            onClick={handleClose}
            disabled={buttonSaveHasBeenClicked}>
            {t("close")}
          </Button>
          <Button
            id="modal-save-button"
            onClick={handleSave}
            disabled={
              Object.keys(errors).length > 0 || buttonSaveHasBeenClicked
            }>
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withTranslation()(withSnackbar(NewIntentModal));
